import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Stack, Typography } from '@mui/material';

import { OrderDetailResponse } from '../../../client';
import Iconify from '../../../components/iconify/Iconify';
import { useSetPaginationFlag } from '../../../components/table/usePersistentPagination';
import { APP_ROUTES } from '../../../constants/AppRoutes';
import { useOrderDetailQuery } from '../../../recoil/order/useOrder';
import ChatBox from './ChatWindow';
import { OrderDetailAction } from './OrderDetailAction';
import { OrderDetailInfo } from './OrderDetailInfo';
import { RemakeHistorySelect } from './RemakeHistorySelect';
import BoCustomSendbirdProvider from './Tabs/BoCustomSendbirdProvider';
import { OrderDetailTabs } from './Tabs/OrderDetailTabs';

const OrderDetailPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { data } = useOrderDetailQuery(id ?? '');
  const orderDetailInfo = data?.data as OrderDetailResponse;

  // retry channel Info
  const retryChannelInfo = {
    ordererId: orderDetailInfo.orderer.userId,
    orderId: orderDetailInfo.id,
    patientName: orderDetailInfo.patient.name,
  };

  useSetPaginationFlag('orderDetail');

  return (
    <Stack sx={{ width: '100%', padding: '40px 40px 0 40px' }}>
      <Stack
        gap="20px"
        sx={{
          width: '100%',
          marginBottom: '20px',
          justifyContent: 'space-between',
        }}
      >
        <Button
          variant="text"
          color="inherit"
          size="large"
          startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}
          onClick={() => navigate(`/${APP_ROUTES.ORDER_LIST.ROUTE}`)}
          sx={{ alignSelf: 'flex-start' }}
        >
          Back
        </Button>
        {orderDetailInfo && <RemakeHistorySelect orderDetailInfo={orderDetailInfo} />}
      </Stack>
      {orderDetailInfo && (
        <Stack direction="column" gap="40px" sx={{ width: '100%' }}>
          <OrderDetailInfo
            orderDetailInfo={orderDetailInfo}
            // orignalOrderDateOfRemakeOrder={orignalOrderDateOfRemakeOrder}
          />
          <Stack
            direction="row"
            gap="32px"
            sx={{ justifyContent: 'space-between', alignItems: 'flex-start', paddingBottom: '8px', width: '100%' }}
          >
            <Stack sx={{ flexGrow: 1 }}>
              <OrderDetailTabs orderDetailInfo={orderDetailInfo} />
            </Stack>

            <Stack direction="column" gap="24px" sx={{ flexGrow: 1 }}>
              <BoCustomSendbirdProvider
                chattingChannelUrl={orderDetailInfo.chattingChannelUrl}
                retryChannelInfo={retryChannelInfo}
              >
                <OrderDetailAction orderDetailInfo={orderDetailInfo} />
                <Card sx={{ flexGrow: 1 }}>
                  <Stack direction="column" gap="20px" sx={{ padding: '32px 24px' }}>
                    <Typography variant="h5">Chat</Typography>
                    <ChatBox chattingChannelUrl={orderDetailInfo.chattingChannelUrl ?? ''} />
                  </Stack>
                </Card>
              </BoCustomSendbirdProvider>
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default OrderDetailPage;
