import React, { ReactNode } from 'react';
import { useAuth } from '@imago-cloud/auth-client';

import { LoadingScreen } from '../components/loading/LoadingScreen';
import { useAuthInitialization } from './useAuthInitialization';

const AUTH_ERROR: { [key: string]: { title: string; message: string; redirectUrl: string } } = {
  USER_ROLE_NOT_ALLOWED: {
    title: '역할 제한',
    message: '해당 역할로 이 페이지에 접근할 수 없습니다.',
    redirectUrl: process.env.REACT_APP_CONNECT_CLIENT_URL as string,
  },
  default: {
    title: '접근 오류',
    message: '이 페이지에 접근할 수 없습니다.',
    redirectUrl: process.env.REACT_APP_CONNECT_CLIENT_URL as string,
  },
};

export const AuthHoc = ({ children }: { children: ReactNode }) => {
  const { authState } = useAuth();
  const { isAuthenticated } = authState;
  const { isBoUser } = useAuthInitialization();

  if (!isBoUser || !isAuthenticated) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
};
