import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import { useState } from 'react';
import { Badge, BadgeProps, Button, Stack, TableCell, TableRow, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { alpha, useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';

import { OrderDetailResponse, OrganizationWithCapacity } from '../../../../client';
import { DataTableWithScroll } from '../../../../components/table/DataTableWithScroll';
import { TableLoading } from '../../../../components/table/TableLoading';
import { TableNoData } from '../../../../components/table/TableNoData';
import { ICloseButtonOnlyDialogueProps } from '../../../../constants/helperHooks/useDialogue';
import { useInvitePartnerLab } from '../../../../hooks/chat/sendbirdSDK';
import { useOrderAssignToLabQuery, useOrdersUserInfoQuery } from '../../../../recoil/order/useOrder';
import { useOrganizationActions } from '../../../../recoil/organizations/useOrganizations';
import useTopAlert from '../../../../recoil/topAlert/useTopAlert';

type AssignLabDialogueType = ICloseButtonOnlyDialogueProps & {
  orderDetailInfo: OrderDetailResponse;
};

const tableColumn = [
  {
    id: 'organizationName',
    label: 'Organization name',
    alignCol: 'left',
    maxWidth: 248,
  },
  { id: 'organizationAdddress', label: 'Organization address', alignCol: 'left', maxWidth: 432 },
  {
    id: 'remake',
    label: 'Remake(%/Total)',
    alignCol: 'center',
    maxWidth: 148,
  },
  { id: 'sales', label: 'Sales/Month', alignCol: 'center', maxWidth: 166 },
  { id: 'averageRating', label: 'Average rating', alignCol: 'center', maxWidth: 180 },
  { id: 'capacity', label: 'Capacity', alignCol: 'center', maxWidth: 108 },
];

export const AssignLabDialogue = ({ open, onClose, orderDetailInfo }: AssignLabDialogueType) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const [selectedLab, setSelectedLab] = useState<OrganizationWithCapacity>();
  const { mutateAsync: inviteAsync } = useInvitePartnerLab(orderDetailInfo.chattingChannelUrl as string);

  const { orderer, shippingAddress, id } = orderDetailInfo ?? {};
  const { useOrganizationAvailableList } = useOrganizationActions();
  const { data, isFetching } = useOrganizationAvailableList(id);
  const organizationListData = data?.data;

  const { data: organizationRemakeRateData } = useOrdersUserInfoQuery(orderer.userId);
  const organizationRemakeRate = organizationRemakeRateData?.data;

  const { mutateAsync } = useOrderAssignToLabQuery();
  const { setTopAlert } = useTopAlert();

  const handleConfirmButtonClick = async () => {
    try {
      if (selectedLab && id) {
        await mutateAsync(
          { labId: selectedLab.id, orderId: id },
          {
            onSuccess: () => {
              queryClient.invalidateQueries({
                queryKey: ['orderDetail', id],
              });
              onClose();
              setTopAlert({
                open: true,
                variant: 'filled',
                severity: 'success',
                description: `Order(${id}) has been assigned to ${selectedLab.name}`,
              });
            },
          },
        );
        // Invite PartnerLab
        await inviteAsync(selectedLab.id);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: '1476px',
          width: '100%',
        },
      }}
    >
      <DialogTitle>User order details</DialogTitle>
      <DialogContent sx={{ paddingBottom: 0, width: '100%' }}>
        <Stack
          direction="row"
          gap="12px"
          justifyContent="flex-start"
          alignItems="flex-start"
          sx={{ paddingBottom: '16px', width: '100%' }}
        >
          <OrderInfoStyledStack direction="column" gap="8px" sx={{ width: '550px' }}>
            <Typography variant="subtitle1">Order information</Typography>
            <Stack direction="column" gap="4px">
              <OrderInfoRow label={'Organization name:'} value={orderer.organization.name} />
              <OrderInfoRow label={'User name:'} value={`${orderer.name.firstName} ${orderer.name.lastName}`} />
              <OrderInfoRow label={'Email:'} value={orderer.email ?? ''} />
              <OrderInfoRow label={'Organization address:'} value={shippingAddress?.fullAddress ?? ''} />
              <OrderInfoRow
                label={'Remake(%/Total):'}
                value={
                  organizationRemakeRate
                    ? `${organizationRemakeRate?.remakeRate}%/${organizationRemakeRate?.totalOrderCnt}`
                    : ''
                }
              />
            </Stack>
          </OrderInfoStyledStack>
          {/*<OrderInfoStyledStack direction="column" gap="12px" sx={{ width: '419px' }}>*/}
          {/*  <Typography variant="subtitle1">Previously assigned laboratory</Typography>*/}
          {/*  <Stack direction="column" gap="4px">*/}
          {/*    <OrderInfoRow label={'Most recent:'} value={'Spinal Fluence'} />*/}
          {/*    <OrderInfoRow label={'Most frequent:'} value={'Ci medical lab'} />*/}
          {/*  </Stack>*/}
          {/*</OrderInfoStyledStack>*/}
        </Stack>

        <Stack direction="column" gap="16px" sx={{ py: '16px', maxHeight: '436px', width: '100%' }}>
          <Typography variant="h6">Assign laboratory</Typography>
          <DataTableWithScroll tableColumn={tableColumn}>
            {isFetching ? (
              <TableLoading style={{ height: '304px' }} colSpan={tableColumn.length} />
            ) : organizationListData?.length ? (
              organizationListData?.map((row, index) => {
                const isSelected = selectedLab && selectedLab.id === row.id;
                return (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor: isSelected ? alpha(theme.palette.primary.main, 0.12) : 'background.color',
                      cursor: 'pointer',
                      '&:hover': !isSelected
                        ? {
                            backgroundColor: 'action.hover',
                          }
                        : {},
                      '& td': {
                        color: isSelected ? theme.palette.primary.main : 'text.primary',
                        fontWeight: isSelected ? '600 !important' : '400',
                      },
                    }}
                    onClick={() => {
                      setSelectedLab(row);
                    }}
                  >
                    <TableCell align="left" sx={{ maxWidth: 248 }}>
                      {row.name}
                    </TableCell>
                    <TableCell align="left" sx={{ maxWidth: 432 }}>
                      {row.address.fullAddress}
                    </TableCell>
                    <TableCell align="center" sx={{ maxWidth: 148 }}>
                      {`${row.remakeRate}%/${row.totalOrderCnt}`}
                    </TableCell>
                    <TableCell align="center" sx={{ maxWidth: 166 }}>
                      {row.saleAmount}
                    </TableCell>
                    <TableCell align="center" sx={{ maxWidth: 180 }}>
                      {row.averageRate}
                    </TableCell>
                    <TableCell align="center" sx={{ maxWidth: 108 }}>
                      <Badge
                        color={CapacityLevelBadgeColor(row.currentCapacity)}
                        variant="dot"
                        sx={{
                          '& .MuiBadge-dot': {
                            width: '12px',
                            height: '12px',
                            borderRadius: '500px',
                          },
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableNoData
                style={{ height: '304px' }}
                colSpan={tableColumn.length}
                content={'No labs available to assign'}
              />
            )}
          </DataTableWithScroll>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ border: 'none !important' }}>
        <Button variant="outlined" color="inherit" size="large" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          disabled={!selectedLab?.id}
          onClick={handleConfirmButtonClick}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CapacityLevelBadgeColor = (capacity: number): BadgeProps['color'] => {
  if (capacity >= 90) {
    return 'error';
  } else if (capacity >= 80) {
    return 'warning';
  } else {
    return 'success';
  }
};

const OrderInfoStyledStack = styled(Stack)(({ theme }) => ({
  padding: '16px',
  border: '1px solid',
  borderRadius: '8px',
  borderColor: theme.palette.divider,
  height: '190px',
}));

const OrderInfoRow = ({ label, value }: { label: string; value: string }) => {
  return (
    <Stack direction="row" textAlign="center" alignItems="center" gap="4px">
      <Typography variant="subtitle2" sx={{ whiteSpace: 'nowrap' }}>
        {label}
      </Typography>
      <Typography
        variant="subtitle2"
        color="text.secondary"
        sx={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          maxWidth: '100%',
        }}
      >
        {value}
      </Typography>
    </Stack>
  );
};
