import axios from 'axios';

import * as authApi from '../recoil/auth/auth.api';
import { TOKEN_REFRESH_URL } from '../recoil/auth/auth.api';

const TIMEOUT = 1 * 60 * 1_000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = process.env.REACT_APP_AUTH_SERVER_URL;
axios.defaults.withCredentials = true;
//
// const setupAxiosInterceptors = () => {
//   const onRequestSuccess = (config: any) => {
//     const token = axios.defaults.headers.common['Authorization'];
//     if (token) {
//       config.headers.Authorization = token;
//     }
//     return config;
//   };
//
//   const onResponseSuccess = (response: any) => {
//     return response;
//   };
//
//   const onResponseError = async (err: any) => {
//     const originalRequest = err.config;
//     const status = err.response ? err.response.status : 0;
//
//     if (!originalRequest) {
//       return Promise.reject(err);
//     }
//
//     if (originalRequest.url.includes(TOKEN_REFRESH_URL)) {
//       return window.location.replace(process.env.REACT_APP_ACCOUNTS_CLIENT_URL as string);
//     }
//
//     if (!originalRequest._retry && (status === 401 || status === 0)) {
//       originalRequest._retry = true;
//
//       try {
//         const newAccessToken = await authApi.adminLoginApi();
//         axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
//         originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
//         return axios(originalRequest);
//       } catch (error) {
//         return Promise.reject(error);
//       }
//     }
//     return Promise.reject(err);
//   };
//   axios.interceptors.request.use(onRequestSuccess);
//   axios.interceptors.response.use(onResponseSuccess, onResponseError);
// };
//
// export default setupAxiosInterceptors;
