import * as React from 'react';
// import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';

import CloseIcon from '../../assets/images/ic_close.svg';

import '../css/dialog.min.css';

interface IPropsDialog {
  iconClose?: boolean;
  open: boolean;
  title: any;
  variant?: any;
  width?: string;
  customStyle?: any;
  btnCancelText?: string;
  handleClose?: any;
  children?: React.ReactNode;
  isDisabled?: boolean;
  hideCloseViaClickOnbackDrop?: boolean;
  btnActionText?: string;
  btnAction?: () => void;
  btnDisabled?: boolean;
  btnType?: 'submit';
  formId?: string;
  isCancelSection?: boolean;
  isActionSection?: boolean;
}

export default function CustomDialog({
  title,
  width = '100%',
  iconClose = false,
  variant = 'h6',
  customStyle,
  children,
  open,
  btnCancelText,
  handleClose,
  hideCloseViaClickOnbackDrop = false,
  btnActionText,
  btnAction,
  btnDisabled,
  btnType,
  formId,
  isCancelSection = true,
  isActionSection = true,
}: Readonly<IPropsDialog>) {
  return (
    <Dialog
      open={open}
      onClose={!hideCloseViaClickOnbackDrop ? handleClose : () => {}}
      sx={{
        '& .MuiDialog-paper': {
          width,
          ...customStyle,
        },
      }}
    >
      <DialogTitle sx={{ p: '24px', pr: '12px' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant={variant}>{title}</Typography>
          {iconClose && (
            <IconButton aria-label="close" size="medium" onClick={handleClose}>
              <img src={CloseIcon} alt="close-icon" />
            </IconButton>
          )}
        </Stack>
      </DialogTitle>

      <DialogContent>{children}</DialogContent>
      {isActionSection && (
        <DialogActions sx={{ border: 'none !important' }}>
          {isCancelSection && (
            <Button variant={'outlined'} onClick={handleClose} color="primary" size={'medium'}>
              {btnCancelText ?? 'Cancel'}
            </Button>
          )}
          {btnActionText && (
            <Button
              onClick={btnAction}
              variant={'contained'}
              color="primary"
              size={'medium'}
              type={btnType}
              form={formId}
              disabled={btnDisabled ?? false}
            >
              {btnActionText}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}
