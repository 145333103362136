import React from 'react';
import { Button, TableCell, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';

import { PaymentHistoryResponse } from '../../../client';
import CustomDataTable, { ITableColumnTypes } from '../../../components/table/DataTable';
import { TableLoading } from '../../../components/table/TableLoading';
import { TableNoData } from '../../../components/table/TableNoData';
import { TablePropsReturnType } from '../../../components/table/types';
import { InvoiceDTO } from '../../../recoil/dto/response/invoice.dto';
import { getFormatDateWithTime } from '../../../utils/formatDate';
import { getStatusStyle } from './Helper';

const tableColumn: ITableColumnTypes[] = [
  { id: 'date', label: 'Date', maxWidth: 230, alignCol: 'left' },
  {
    id: 'orderNumber',
    label: 'Order number',
    maxWidth: 187,
    alignCol: 'left',
  },
  {
    id: 'userEmail',
    label: 'User email',
    maxWidth: 187,
    alignCol: 'left',
  },
  {
    id: 'actualAmount',
    label: 'Actual amount(JPY)',
    maxWidth: 187,
    alignCol: 'left',
  },
  {
    id: 'transactionID',
    label: 'Transaction ID',
    maxWidth: 187,
    alignCol: 'left',
  },
  { id: 'paymentMethod', label: 'Payment method', maxWidth: 187, alignCol: 'left' },
  {
    id: 'invoice',
    label: 'Invoice',
    maxWidth: 106,
    alignCol: 'center',
  },
];

interface StyledIStatusProps {
  status: string;
}

const StyledStatus = styled('span')<StyledIStatusProps>`
  padding: 0.25rem 0.5rem;
  border-radius: 0.375rem;
  min-width: 100px;
  display: inline-block;
  text-align: center;
  font-weight: 600;
  ${({ status }: any) => getStatusStyle(status)};
`;

interface Iprops {
  invoiceListing: InvoiceDTO[];
  selectedInvoicesForInprogress: string[];
  setSelectedInvoicesForInprogress: React.Dispatch<React.SetStateAction<string[]>>;
  getInvoiceByIdHandler: (id: string) => void;
}
interface Error {
  error: boolean;
  message: string;
}

type PaymentHistoryListTablePropsType = Partial<TablePropsReturnType> & {
  paymentHistoryList: PaymentHistoryResponse[] | undefined;
  totalElement: number;
  isPending: boolean;
};

export default function PaymentHistoryListTable(props: PaymentHistoryListTablePropsType) {
  const {
    dense,
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
    onChangeDense,
    totalElement,
    paymentHistoryList,
    isPending,
  } = props;

  return (
    <CustomDataTable
      tableColumn={tableColumn}
      rowsPerPage={rowsPerPage}
      page={page}
      totalElement={totalElement}
      dense={dense}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onChangeDense={onChangeDense}
    >
      {isPending ? (
        <TableLoading style={{ height: '344px' }} colSpan={tableColumn.length} />
      ) : paymentHistoryList && paymentHistoryList.length > 0 ? (
        paymentHistoryList.map((order: PaymentHistoryResponse, index) => {
          return (
            <TableRow
              key={order.orderId}
              // sx={{
              //   backgroundColor:
              //     order.status === 'ORDERED' ? alpha(theme.palette.primary.main, 0.08) : 'background.default',
              //   '&:hover': {
              //     cursor: 'pointer',
              //     backgroundColor:
              //       order.status === 'ORDERED' ? alpha(theme.palette.primary.main, 0.24) : 'action.hover',
              //   },
              // }}
            >
              <TableCell align="left" sx={{ maxWidth: 230 }}>
                {getFormatDateWithTime(order.paidAt)}
              </TableCell>
              <TableCell align="left" sx={{ maxWidth: 187 }}>
                {order.orderNumber}
              </TableCell>
              <TableCell align="left" sx={{ maxWidth: 187 }}>
                {order.userEmail}
              </TableCell>
              <TableCell align="left" sx={{ maxWidth: 187 }}>
                {`JPY ${order.paymentAmount.toLocaleString()}`}
              </TableCell>
              <TableCell align="left" sx={{ maxWidth: 187 }}>
                {order.stripePaymentIntentId}
              </TableCell>
              <TableCell align="left" sx={{ maxWidth: 187 }}>
                {order.paymentMethodType}
              </TableCell>
              <TableCell align="center" sx={{ minWidth: 106 }}>
                <Button variant="outlined" size="medium" sx={{ width: '106px' }}>
                  View invoice
                </Button>
              </TableCell>
            </TableRow>
          );
        })
      ) : (
        <TableNoData style={{ height: '344px' }} colSpan={tableColumn.length} content={'No payment history yet'} />
      )}
    </CustomDataTable>
  );
}
