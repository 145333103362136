import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export const getSendbirdSessionToken = async (userId: string, expires_at?: string) => {
  return await axios.get(`connect/sendbird/${userId}/session-token${expires_at ? `?expires_at=${expires_at}` : ''}`);
};

export const useSendbirdSessionToken = (userId: string, expires_at?: string) => {
  return useQuery({
    queryKey: ['session-token', userId, expires_at],
    queryFn: () => getSendbirdSessionToken(userId, expires_at),
    enabled: !!userId,
  });
};
