import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';

import { GetPaymentHistory1Data, ResponseDtoPagePaymentHistoryResponse } from '../../client';

type PaymentHistoryListProps = {
  option: {
    page: number;
    size: number;
    sort?: string[];
    startDate?: GetPaymentHistory1Data['startDate'];
    endDate?: GetPaymentHistory1Data['endDate'];
    filterType?: GetPaymentHistory1Data['filterType'];
    searchKeyword?: GetPaymentHistory1Data['searchKeyword'];
  };
};

export const usePaymentHistoryListQuery = ({
  option,
}: PaymentHistoryListProps): UseQueryResult<ResponseDtoPagePaymentHistoryResponse> => {
  return useQuery({
    queryKey: ['paymentHistory', option],
    queryFn: () => axios.get(`connect/back-office/payment/history`, { params: { ...option } }).then((res) => res.data),
    refetchOnWindowFocus: true,
  });
};
