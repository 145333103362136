import React from 'react';
import { Box, Button, MenuItem, Skeleton, Stack, TextField, Typography } from '@mui/material';
import Switch from '@mui/material/Switch';
import { FormikProps } from 'formik';
import humanizeString from 'humanize-string';

import { ProductDetail, ProductOption } from '../../../client';
import Iconify from '../../../components/iconify/Iconify';
import { useDialogue } from '../../../constants/helperHooks/useDialogue';
import { useAllProductOptions } from '../../../recoil/products/useProduct';
import { handleNumberFieldInputValidate } from '../../../utils/inputHandler';
import { NEW_PRODUCT_INIT_STATE } from './NewProductDialog';
import ProsthesisColorEditDialog from './ProsthesisColorEditDialog';

const SELECT_DESIGN_MANUFACTURE_COMBINATION_OPTION = [
  { label: 'Design only', value: 'DESIGN', id: '1' },
  { label: 'Design & Manufacture', value: 'DESIGN_AND_MANUFACTURE', id: '2' },
  { label: 'Manufacture only', value: 'MANUFACTURE', id: '3' },
];
interface SelectDto {
  value: string;
  label: string;
  id: string;
}

interface Iprops {
  formik: FormikProps<typeof NEW_PRODUCT_INIT_STATE>;
  productForEdit?: ProductDetail;
  onProductOptionEditDialogClick: () => void;
}
export const NewProductDialogContent = ({ formik, productForEdit, onProductOptionEditDialogClick }: Iprops) => {
  const { values, errors, handleChange, handleBlur, touched, setFieldValue } = formik;

  const {
    queryLoading,
    prosthesisOption,
    methodOption,
    materialOption,
    shapeOption,
    // priceUnitOption,
    // colorCodeOption,
  } = useAllProductOptions();

  const colorEditDialog = useDialogue();

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: string,
    optionList: Array<ProductOption>,
  ) => {
    const selectedOption = optionList?.find((item) => item.id === e.target.value);

    const formatted = { optionId: selectedOption?.id, value: selectedOption?.value };
    setFieldValue(fieldName, formatted);
  };

  const isEditMode = !!productForEdit;

  if (queryLoading)
    return (
      <div>
        <Skeleton variant="text" width={'100%'} height={50} />
        <Skeleton variant="text" width={'100%'} height={50} />
        <Skeleton variant="text" width={'100%'} height={50} />
      </div>
    );

  return (
    <>
      <Stack sx={{ pb: '24px' }}>
        <Stack sx={{ flexDirection: 'row', gap: '8px' }}>
          <Iconify icon="eva:info-fill" color={'text.disabled'} />
          <Typography variant={'body2'} sx={{ color: 'text.disabled' }}>
            Fields marked with&nbsp;
            <Box component="span" sx={{ color: 'error.main' }}>
              {`*`}
            </Box>
            &nbsp;are required.
          </Typography>
        </Stack>

        {!isEditMode && (
          <Stack sx={{ width: '100%', p: '24px 0' }} alignItems="flex-end">
            <Button variant="outlined" size="medium" onClick={onProductOptionEditDialogClick}>
              Edit Category
            </Button>
          </Stack>
        )}

        <Stack sx={{ gap: '16px' }}>
          {/*Prosthesis*/}
          <Stack sx={{ gap: '8px' }}>
            <Typography variant={'subtitle1'} sx={{ color: 'text.primary' }}>
              Prosthesis
              <Box component="span" sx={{ color: 'error.main' }}>
                *
              </Box>
            </Typography>
            <Stack sx={{ flexDirection: 'row', gap: '8px' }}>
              <TextField
                select={!isEditMode}
                fullWidth
                id="prosthesis"
                name="prosthesis"
                variant="outlined"
                value={isEditMode ? productForEdit?.prosthesis.productOptionValue : values?.prosthesis.optionId}
                onChange={(e) => handleFieldChange(e, 'prosthesis', prosthesisOption as Array<ProductOption>)}
                onBlur={handleBlur}
                error={touched.prosthesis && Boolean(errors.prosthesis?.value)}
                helperText={touched.prosthesis?.value && errors.prosthesis?.value}
                disabled={isEditMode}
                size="small"
                placeholder="Prosthesis"
                sx={{ backgroundColor: isEditMode ? '#919EAB14' : '#fff' }}
              >
                {prosthesisOption?.map((item: ProductOption) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.value}
                  </MenuItem>
                ))}
              </TextField>
              {isEditMode && (
                <Button
                  variant="text"
                  onClick={() => {
                    colorEditDialog.handleOpenDialogue();
                    // handleOpenDialogue();
                    // setSelectedEditOption('PROSTHESIS');
                  }}
                  sx={{ height: '40px' }}
                >
                  Edit
                </Button>
              )}
            </Stack>
          </Stack>

          {/*Method*/}
          <Stack sx={{ gap: '8px' }}>
            <Typography variant={'subtitle1'} sx={{ color: 'text.primary' }}>
              Method
            </Typography>
            <Stack sx={{ flexDirection: 'row', gap: '8px', height: 'fit-content' }}>
              <TextField
                select={!isEditMode}
                fullWidth
                id="method"
                name="method"
                variant="outlined"
                value={isEditMode ? productForEdit?.method : values?.method.optionId}
                onChange={(e) => handleFieldChange(e, 'method', methodOption as Array<ProductOption>)}
                onBlur={handleBlur}
                error={touched.method && Boolean(errors.method)}
                helperText={touched.method?.value && errors.method?.value}
                disabled={isEditMode}
                size="small"
                sx={{ backgroundColor: isEditMode ? '#919EAB14' : '#fff' }}
              >
                {methodOption?.map((item: ProductOption) => (
                  <MenuItem key={item.id} value={item?.id}>
                    {humanizeString(item.value)}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
          </Stack>

          {/*Material*/}
          <Stack sx={{ gap: '8px' }}>
            <Typography variant={'subtitle1'} sx={{ color: 'text.primary' }}>
              Material
              <Box component="span" sx={{ color: 'error.main' }}>
                *
              </Box>{' '}
            </Typography>
            <Stack sx={{ flexDirection: 'row', gap: '8px' }}>
              <TextField
                select={!isEditMode}
                fullWidth
                id="material"
                name="material"
                variant="outlined"
                value={isEditMode ? productForEdit?.material : values?.material.optionId}
                onChange={(e) => handleFieldChange(e, 'material', materialOption as Array<ProductOption>)}
                onBlur={handleBlur}
                error={touched.material && Boolean(errors.material)}
                helperText={touched.material?.value && errors.material?.value}
                disabled={isEditMode}
                size="small"
                sx={{ backgroundColor: isEditMode ? '#919EAB14' : '#fff' }}
              >
                {materialOption?.map((item: ProductOption) => (
                  <MenuItem key={item.id} value={item?.id}>
                    {humanizeString(item.value)}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
          </Stack>

          {/*Shape*/}
          <Stack sx={{ gap: '8px' }}>
            <Typography variant={'subtitle1'} sx={{ color: 'text.primary' }}>
              Shape
            </Typography>
            <Stack sx={{ flexDirection: 'row', gap: '8px', height: 'fit-content' }}>
              <TextField
                select={!isEditMode}
                fullWidth
                id="shape"
                name="shape"
                variant="outlined"
                value={isEditMode ? productForEdit?.shape : values?.shape.optionId}
                onChange={(e) => handleFieldChange(e, 'shape', shapeOption as Array<ProductOption>)}
                onBlur={handleBlur}
                error={touched.shape?.value && Boolean(errors.shape?.value)}
                helperText={touched.shape?.value && errors.shape?.value}
                disabled={isEditMode}
                sx={{ backgroundColor: isEditMode ? '#919EAB14' : '#fff' }}
                size="small"
              >
                {shapeOption?.map((item: ProductOption) => (
                  <MenuItem key={item.id} value={item.id}>
                    {humanizeString(item.value)}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
          </Stack>

          {/*Order Range*/}
          <Stack sx={{ gap: '8px' }}>
            <Typography variant={'subtitle1'} sx={{ color: 'text.primary' }}>
              Order range
              <Box component="span" sx={{ color: 'error.main' }}>
                *
              </Box>
            </Typography>
            <TextField
              select={!isEditMode}
              fullWidth
              id="designManufactureCombination"
              name="designManufactureCombination"
              variant="outlined"
              value={values?.designManufactureCombination}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.designManufactureCombination && Boolean(errors.designManufactureCombination)}
              helperText={touched.designManufactureCombination && errors.designManufactureCombination}
              disabled={isEditMode}
              size="small"
              sx={{ backgroundColor: isEditMode ? '#919EAB14' : '#fff' }}
            >
              {SELECT_DESIGN_MANUFACTURE_COMBINATION_OPTION?.map((item: SelectDto) => {
                return (
                  <MenuItem key={item.id} value={item?.value}>
                    {item?.label}
                  </MenuItem>
                );
              })}
            </TextField>
          </Stack>

          {/*Unique price*/}
          <Stack sx={{ gap: '8px' }}>
            <Typography variant={'subtitle1'} sx={{ color: 'text.primary' }}>
              Unique price
              <Box component="span" sx={{ color: 'error.main' }}>
                *
              </Box>
            </Typography>
            <TextField
              size="small"
              fullWidth
              id="price"
              name="price"
              variant="outlined"
              value={values?.price}
              onChange={(e) => {
                const value = e.target.value;
                setFieldValue('price', value ? Number(value) : '');
              }}
              onBlur={(e) => {
                const value = e.target.value;
                setFieldValue('price', value ? Number(value) : 0);
                handleBlur(e);
              }}
              error={touched.price && Boolean(errors.price)}
              helperText={touched.price && errors.price}
              inputProps={{
                onInput: handleNumberFieldInputValidate,
                maxLength: 28,
              }}
            />
          </Stack>

          <Stack sx={{ flexDirection: 'row', gap: '9px', alignItems: 'center', ml: '-12px' }}>
            <Switch
              id={'requireScanBodyAndImplantInfo'}
              name={'requireScanBodyAndImplantInfo'}
              value={values.requireScanBodyAndImplantInfo}
              onChange={handleChange}
            />
            <Typography variant={'body2'} color={'test.primary'} sx={{ ml: '-12px' }}>
              Request for Scanbody and Implant Details
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      {productForEdit && (
        <ProsthesisColorEditDialog
          open={colorEditDialog.open}
          handleClose={colorEditDialog.handleCloseDialogue}
          productForEdit={productForEdit}
        />
      )}
    </>
  );
};
