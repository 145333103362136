import { matchPath, useLocation } from 'react-router-dom';

// ----------------------------------------------------------------------

type ReturnType = {
  active: boolean | undefined;
  isExternalLink: boolean;
};

export default function useActiveLink(path: string, detailPaths?: string[], deep = true): ReturnType {
  const { pathname } = useLocation();

  const normalActive = path ? !!matchPath({ path, end: true }, pathname) : false;
  const deepActive = path ? !!matchPath({ path, end: false }, pathname) : false;

  const isDetailActive = detailPaths?.some((detailPath) => !!matchPath({ path: detailPath, end: false }, pathname));

  return {
    active: deep ? deepActive || isDetailActive : normalActive || isDetailActive,
    isExternalLink: path.includes('http'),
  };
}
