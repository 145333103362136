import { useEffect, useState } from 'react';

import { useUserQuery } from '../recoil/auth/useAuth';
import { useAxiosErrorWithErrorCode } from '../utils/responseError';

export const useAuthInitialization = () => {
  const { data: userInfo, error, isLoading } = useUserQuery();
  const [isBoUser, setIsBoUser] = useState(false);
  const { isAxiosErrorWithErrorCode, errorCode, resetError } = useAxiosErrorWithErrorCode();

  useEffect(() => {
    if (isLoading) return;

    if (error !== null) {
      if (isAxiosErrorWithErrorCode(error, 'CONNECT:USER_ROLE_NOT_ALLOWED')) {
        // Connect User, but NOT QC
        return window.location.replace(process.env.REACT_APP_CONNECT_CLIENT_URL as string);
      } else if (isAxiosErrorWithErrorCode(error, 'CONNECT:USER_TENANT_NOT_ALLOWED')) {
        // NOT Ci-medical
        return window.location.replace(process.env.REACT_APP_CLOUD_CLIENT_URL as string);
      }
    }
    setIsBoUser(true);
  }, [isLoading, userInfo, error]);

  return { isBoUser };
};
