import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext';
import { useEffect } from 'react';

import { useCreateChannel, UseCreateChannelProps } from '../../../../hooks/chat/sendbirdSDK';
import { useOrderAddChat } from '../../../../recoil/order/useOrder';

interface GroupChannelRetryProps {
  chattingChannelUrl: string | undefined;
  retryChannelInfo: UseCreateChannelProps;
  children: React.ReactNode;
}
export default function GroupChannelRetry({ chattingChannelUrl, retryChannelInfo, children }: GroupChannelRetryProps) {
  const { stores } = useSendbirdStateContext();
  const sdk = stores.sdkStore.sdk;
  const { mutateAsync: createChannelAsync } = useCreateChannel();
  const { mutateAsync: patchChannelUrl } = useOrderAddChat(retryChannelInfo.orderId);

  useEffect(() => {
    if (!sdk) return;
    const createChannel = async () => {
      try {
        const channel = await createChannelAsync(retryChannelInfo);

        if (!channel) return;
        return channel;
      } catch (err) {
        console.log(err);
      }
    };

    if (!chattingChannelUrl) {
      createChannel().then((channel) => {
        if (!channel) {
          return;
        }
        patchChannelUrl({ chattingChannelUrl: channel.url })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => console.log(err));
      });
    }
  }, [chattingChannelUrl, sdk]);

  return children;
}
