import React, { useState } from 'react';
import { Card, Stack, Typography } from '@mui/material';

import { useInvoiceActions } from '../../../recoil/invoice/useInvoice';
import SalesStatisticsTable from './Table';

const SalesStaticticsPage = () => {
  const [yearList, setYearList] = useState<number[]>([]);
  // const { getAllLabNamesList, allLabList= } = useRatingActions();
  const { getInvoiceStatictics, invoiceStatistics, isLoading } = useInvoiceActions();
  // const [selectedYear, setSeletedYear] = useState<UserSearch>({ searchBy: `${new Date().getFullYear()}` });
  // const [selectedLabName, setSeletedLabName] = useState<UserSearch>({ searchBy: '' });
  // useEffect(() => {
  //   let allYears: number[] = [];
  //   for (let i = MAKING_YEAR_FOR_PROJECT; i <= +moment().format('YYYY'); i++) {
  //     allYears.push(i);
  //   }
  //   setYearList(allYears);
  //   getAllLabNamesList();
  //   getInvoiceStatictics({ year: +moment().format('YYYY'), labName: '' });
  //   // eslint-disable-next-line
  // }, []);
  // useEffect(() => {
  //   getInvoiceStatictics({
  //     year: Number(selectedYear.searchBy),
  //     labName: selectedLabName?.searchBy === 'Search By' ? '' : selectedLabName?.searchBy!,
  //   });
  //   // eslint-disable-next-line
  // }, [selectedYear, selectedLabName]);
  return (
    <Stack gap="40px" sx={{ px: 7, py: 5 }}>
      <Typography variant="h3">Sales statistics</Typography>
      {/*<SalesStatisticsTableToolbar*/}
      {/*  selectedYear={selectedYear.searchBy}*/}
      {/*  invoiceStatistics={invoiceStatistics}*/}
      {/*  yearList={yearList}*/}
      {/*  labList={[]}*/}
      {/*  setSeletedYear={setSeletedYear}*/}
      {/*  setSeletedLabName={setSeletedLabName}*/}
      {/*  selectedLab={selectedLabName?.searchBy}*/}
      {/*/>*/}
      <Card>
        {/*<Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ padding: '24px' }}>*/}
        {/*<CommonFilterBar*/}
        {/*  filterList={['date', 'searchTarget', 'searchText']}*/}
        {/*  resetPage={resetPage}*/}
        {/*  onDataChange={handleDataChange}*/}
        {/*  searchTargetList={orderSearchTargetList}*/}
        {/*  searchTargetName={'Search target'}*/}
        {/*/>*/}
        {/*  <Button*/}
        {/*    variant="outlined"*/}
        {/*    color="primary"*/}
        {/*    size="medium"*/}
        {/*    startIcon={<Iconify icon="ic:outline-file-download" />}*/}
        {/*  >*/}
        {/*    Export*/}
        {/*  </Button>*/}
        {/*</Stack>*/}
        <SalesStatisticsTable invoiceStatistics={invoiceStatistics} />
      </Card>
    </Stack>
  );
};

export default SalesStaticticsPage;
