import { useMutation, useQuery, useQueryClient, UseQueryResult, useSuspenseQuery } from '@tanstack/react-query';

import {
  GetOrganizationMembersData,
  GetOrganizations2Data,
  GetProductSupplyPricesData,
  ResponseDtoListOrganizationWithCapacity,
} from '../../client';
import {
  getAvailableOrganizationList,
  getOrganizationDetails,
  getOrganizationList,
  getOrganizationMemberList,
  getOrganizationSupplyList,
  patchOrganizationInfo,
  patchOrganizationSupplyList,
  patchOrganizationType,
} from './organization.api';

export function useOrganizationActions() {
  const queryClient = useQueryClient();

  const useOrganizationList = ({ page, size, sort, organizationType, searchKeyword }: GetOrganizations2Data) => {
    return useQuery({
      queryKey: ['organization-list', page, size, sort, organizationType, searchKeyword],
      queryFn: getOrganizationList,
    });
  };
  const useOrganizationDetails = (organizationId: string) => {
    return useSuspenseQuery({ queryKey: ['organization-details', organizationId], queryFn: getOrganizationDetails });
  };
  const useOrganizationMemberList = ({ organizationId, page, size, sort }: GetOrganizationMembersData) => {
    return useSuspenseQuery({
      queryKey: ['organization-members', organizationId, page, size, sort],
      queryFn: getOrganizationMemberList,
    });
  };

  const useOrganizationTypeMutation = () => {
    return useMutation({
      mutationFn: patchOrganizationType,
    });
  };
  const useOrganizationInfoMutation = () => {
    return useMutation({
      mutationFn: patchOrganizationInfo,
    });
  };

  const useOrganizationSupplyProductList = ({ organizationId, page, size }: GetProductSupplyPricesData) => {
    return useSuspenseQuery({
      queryKey: ['organization-supplyList', organizationId, page, size],
      queryFn: getOrganizationSupplyList,
    });
  };

  const useOrganizationSupplyProductMutation = () => {
    return useMutation({
      mutationFn: patchOrganizationSupplyList,
      onSettled: async () => await queryClient.invalidateQueries({ queryKey: ['organization-supply-price'] }),
    });
  };

  const useOrganizationAvailableList = (orderId: string): UseQueryResult<ResponseDtoListOrganizationWithCapacity> => {
    return useQuery({
      queryKey: ['available-organizationList', orderId],
      queryFn: () => getAvailableOrganizationList(orderId),
    });
  };

  return {
    useOrganizationList,
    useOrganizationDetails,
    useOrganizationMemberList,
    useOrganizationTypeMutation,
    useOrganizationInfoMutation,
    useOrganizationSupplyProductList,
    useOrganizationSupplyProductMutation,
    useOrganizationAvailableList,
  };
}
