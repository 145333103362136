import React, { useState } from 'react';
import { Button, Card, Stack, Typography } from '@mui/material';

import Iconify from '../../../components/iconify/Iconify';
import CommonFilterBar, { CaseToolbarDataTypes } from '../../../components/table/CommonFilterBar';
import useTable from '../../../components/table/useTable';

const RefundPage = () => {
  const { dense, page, rowsPerPage, onChangeDense, onChangePage, onChangeRowsPerPage, setPage } = useTable({
    listName: 'paymentHistoryList',
    defaultRowsPerPage: 10,
    defaultCurrentPage: 0,
  });

  const [extraFilter, setExtraFilter] = useState({});
  const resetPage = () => {
    setPage(0);
  };

  const handleDataChange = (data: CaseToolbarDataTypes) => {
    const { startDate, endDate, searchText, searchTarget } = data;
    // setExtraFilter({
    //   startDate: startDate?.toISOString(),
    //   endDate: endDate?.add(1, 'day').toISOString(),
    //   ...(searchText !== ''
    //     ? { filterType: !searchTarget && searchText ? FILTER_TYPE.PATIENT_NAME : searchTarget }
    //     : {}),
    //
    //   ...(searchText !== '' && { searchKeyword: searchText }),
    // });
    resetPage();
  };
  return (
    <Stack gap="40px" sx={{ px: 7, py: 5 }}>
      <Typography variant="h3">Refund</Typography>
      <Card>
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ padding: '24px' }}>
          <CommonFilterBar filterList={['date', 'searchText']} resetPage={resetPage} onDataChange={handleDataChange} />
          <Button
            variant="outlined"
            color="primary"
            size="medium"
            startIcon={<Iconify icon="ic:outline-file-download" />}
            sx={{ minWidth: '98px' }}
          >
            Export
          </Button>
        </Stack>
      </Card>
    </Stack>
  );
};

export default RefundPage;
