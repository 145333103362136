import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider';
import React from 'react';
import { CircularProgress } from '@mui/material';

import { UseCreateChannelProps } from '../../../../hooks/chat/sendbirdSDK';
import { useSendbirdSessionToken } from '../../../../recoil/chat/chat.api';
import GroupChannelRetry from './GroupChannelRetry';

interface ChatBoxProps {
  chattingChannelUrl: string | undefined;
  children: React.ReactNode;
  retryChannelInfo: UseCreateChannelProps;
}

function BoCustomSendbirdProvider({ chattingChannelUrl, children, retryChannelInfo }: ChatBoxProps) {
  const { data: sessionToken, isLoading } = useSendbirdSessionToken('QC');

  if (isLoading || !sessionToken || !sessionToken.data.data.token) {
    return <CircularProgress color={'inherit'} size={20} sx={{ ml: 1 }} />;
  }

  return (
    <SendbirdProvider
      appId={process.env.REACT_APP_SENDBIRD_APP_ID as string}
      userId={'QC'}
      accessToken={sessionToken?.data.data.token}
      nickname={'Admin'}
    >
      <GroupChannelRetry retryChannelInfo={retryChannelInfo} chattingChannelUrl={chattingChannelUrl}>
        {children}
      </GroupChannelRetry>
    </SendbirdProvider>
  );
}

export default BoCustomSendbirdProvider;
