import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Divider, Stack, Typography } from '@mui/material';

import BrandIcon from '../../../assets/images/dentbirdLogo.svg';
import { OrderDetailResponse, OrderItemRequest } from '../../../client';
import { FDIToCustomSelectorNumber, FDIToPalmer } from '../../../utils/convertToPalmer';
import { getFormatDate, getFormatDateWithTime } from '../../../utils/formatDate';
import { capitalizeFirstLetter, formattedAddress, formattedPrice } from '../../../utils/formatString';
import { convertOrderRangeText } from '../Helper';
import { BRIDGE_STYLE, BRIDGE_STYLE_KEY } from './BridgeSelectedStyle';
import {
  RX_BRIDGE_DATA_SET,
  RX_PALMER_DATA_SET,
  RX_SQUARE_BRIDGE_SET,
  RX_SQUARE_DATA_SET,
} from './RxSelector.constant';
import { TOOTH_STYLE } from './ToothSelectedStyle';

const PATIENT_INFO = ({ patient, orderedAt, orderer }: OrderDetailResponse) => [
  { key: 'Issued', value: `${getFormatDateWithTime(orderedAt)}` },
  { key: 'Patient name (O/N)', value: `${patient.name}` },
  {
    key: 'Birthdate / Gender',
    value: `${patient.birth ? getFormatDate(patient.birth) : '-'} / ${patient.age || '-'} / ${patient.gender}`,
  },
  { key: 'Organization', value: `${orderer.organization.name}` },
  { key: 'User', value: `${orderer.name.firstName} ${orderer.name.lastName}` },
];

const ORDER_INFO = ({ designManufactureCombination, patient, designConfirmProcessIncluded }: OrderDetailResponse) => [
  { key: 'Order range', value: `${convertOrderRangeText(designManufactureCombination)}` },
  { key: 'Try-in date', value: `${patient.tryInDate ? getFormatDate(patient.tryInDate) : '-'}` },
  { key: 'Visit date', value: `${patient.visitDate ? getFormatDate(patient.visitDate) : '-'}` },
  { key: 'Design confirm process', value: `${designConfirmProcessIncluded ? 'Include' : 'Exclude'}` },
];

const PAYMENT_INFO = ({
  orderItems,
  printingFilesAmount,
  shippingFee,
  billingAmount,
  orderAmount,
  patientPrintingFilesResponse,
}: OrderDetailResponse) => {
  const orderItmeList = orderItems.map((item) => ({
    key: FDIToPalmer[item.toothNumber]?.full,
    value: formattedPrice(item.amount),
  }));

  return [
    { key: `Item(${orderItems.length})`, value: formattedPrice(orderAmount) },
    ...orderItmeList,
    ...(patientPrintingFilesResponse.length > 0
      ? [{ key: 'Additional options', value: formattedPrice(printingFilesAmount) }]
      : []),
    { key: 'Shipping fee', value: formattedPrice(shippingFee) },
    { key: `Divider` },
    { key: 'Total', value: formattedPrice(billingAmount) },
  ];
};

interface Iprops {
  userOrder: OrderDetailResponse;
  rxPrintRef: any;
}

export const OrderPrintRx = ({ rxPrintRef, userOrder }: Iprops) => {
  const { orderItems } = userOrder;

  const selectedToothList = orderItems.map(({ groupId, material, prosthesis, toothNumber }) => {
    return { groupId, material, prosthesis, toothNumber: FDIToCustomSelectorNumber[toothNumber] };
  });

  const immutableOrderItems = orderItems.map((item) => {
    const updated = { ...item };
    updated.toothNumber = FDIToCustomSelectorNumber[updated.toothNumber];
    return updated;
  });
  const selectedBridgeNumberPairList = immutableOrderItems
    .sort((a, b) => a.toothNumber - b.toothNumber)
    .reduce<Array<OrderItemRequest>[]>((acc, item) => {
      const lastGroup = acc[acc.length - 1];
      if (
        lastGroup &&
        lastGroup[lastGroup.length - 1].toothNumber === item.toothNumber - 1 &&
        lastGroup[0].material === item.material &&
        item.groupType === 'BRIDGE' &&
        lastGroup[0].groupId === item.groupId
      ) {
        lastGroup.push(item);
      } else {
        acc.push([item]);
      }
      return acc;
    }, [])
    .filter((group) => {
      return group.length > 1;
    })
    .flatMap((group) => {
      const pairs: Array<string> = [];
      for (let i = 0; i < group.length; i++) {
        if (group[i] === group[group.length - 1])
          continue; // 마지막 요소 진행 x
        else if (group[i].groupId && group[i + 1].groupType) {
          pairs.push(`${group[i].toothNumber}-${group[i + 1].toothNumber}`);
        }
      }
      return pairs;
    });
  // const [child_teeth_svg_image_array, setChild_Teeth_svg_image_array] =
  //   useState<TEETH_DTO[]>(MIXED_TEETH_SVG_IMAGE_ARRAY);
  //
  // const [userOrderSelectedTeethArray, setUserOrderSelectedTeethArray] = useState<TEETH_DTO[]>([]);
  // const [selectedUpperConstantTeeth, setSelectedUpperConstantTeeth] = useState<any>([]);
  // const [selectedLowerConstantTeeth, setSelectedLowerConstantTeeth] = useState<any>([]);
  // const [selectedChildUpperConstantTeeth, setSelectedChildUpperConstantTeeth] = useState<any>([]);
  // const [selectedChildLowerConstantTeeth, setSelectedChildLowerConstantTeeth] = useState<any>([]);
  //
  // useEffect(() => {
  //   setSelectedUpperConstantTeeth(PALMER_PRINT_RX_UPPER_TOOTH);
  //   setSelectedLowerConstantTeeth(PALMER_PRINT_RX_LOWER_TOOTH);
  //   setSelectedChildUpperConstantTeeth(PALMER_DECIDIOUS_UPPER_TOOTH);
  //   setSelectedChildLowerConstantTeeth(PALMER_DECIDIOUS_LOWER_TOOTH);
  // }, [userOrder]);

  // useEffect(() => {
  //   if (CHECK_ARRAY_IS_EMPTY_OR_NOT(userOrder?.products)) {
  //     setUserOrderSelectedTeethArray([
  //       ...userOrder.products.map((item: any) => {
  //         return {
  //           backwardBridgeTeeth: item.backwardBridgeTeeth,
  //           bridge: false,
  //           forwardBridgeTeeth: item.forwardBridgeTeeth,
  //           material: item.material,
  //           method: item.method,
  //           number: item.teeth.number,
  //           placementOfTeeth: item.teeth.placement,
  //           product: item.product,
  //           readableNumber: item.teeth.readableNumber,
  //         };
  //       }),
  //     ]);
  //   } else {
  //     setUserOrderSelectedTeethArray([]);
  //   }
  // }, [userOrder]);

  // const checkTeethNumber = (teeth: any) => {
  //   return teeth?.readableNumber;
  //   // if (userOrder?.products[0]?.teeth?.teethDentalNotation === DENTAL_NOTATION.PALMER_SYSTEM || userOrder?.products[0]?.teeth?.teethDentalNotation === DENTAL_NOTATION.ADA_SYSTEM) {
  //   //   return teeth.readableNumber;
  //   // } else {
  //   //   return teeth?.number;
  //   // }
  // };

  return (
    <Box
      ref={rxPrintRef}
      sx={{
        fontFamily: 'Inter',
        position: 'absolute',
        top: '100%',
        right: '100%',
        padding: '30px',
        width: '210mm',
        minHeight: 'fit-content',
        boxSizing: 'border-box',
      }}
    >
      <PatientInfoSection userOrder={userOrder} />

      <Divider style={{ margin: '20px 0 2px' }} />

      <Stack>
        <Box>
          <Typography fontWeight={'700'} fontSize={'10px'} lineHeight={'14px'} sx={{ fontFamily: 'inherit' }}>
            Information
          </Typography>
        </Box>

        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          gap="20px"
          sx={{ width: '100%', height: '256px' }}
        >
          <Stack sx={{ width: '132px', height: '224px', margin: '16px 30px' }}>
            {/*  Mock */}
            <RxPalmer
              selectedToothList={selectedToothList}
              selectedBridgeNumberPairList={selectedBridgeNumberPairList}
            />
          </Stack>
          <Box sx={{ width: '329px', height: '256px' }}>
            <RxSquare
              selectedToothList={selectedToothList}
              selectedBridgeNumberPairList={selectedBridgeNumberPairList}
            />
          </Box>
        </Stack>
      </Stack>

      <Divider style={{ margin: '20px 0 6px' }} />

      <OrderInfoSection userOrder={userOrder} />
      <Divider style={{ margin: '20px 0 6px' }} />

      {/*  <Box marginTop={'2rem'}>*/}
      {/*    <Typography fontWeight={'700'} fontSize={'10px'} lineHeight={'14px'}>*/}
      {/*      {'RX Tooth Number'}*/}
      {/*    </Typography>*/}
      {/*    <div style={{ display: 'flex', marginTop: '16px', alignItems: 'center' }}>*/}
      {/*      <Box sx={{ paddingTop: '30px' }}>*/}
      {/*        /!*<PalmerDentTeethSvg*!/*/}
      {/*        /!*  teeth_svg_image_array={child_teeth_svg_image_array}*!/*/}
      {/*        /!*  setTeeth_svg_image_array={setChild_Teeth_svg_image_array}*!/*/}
      {/*        /!*  userOrderSelectedTeethArray={userOrderSelectedTeethArray}*!/*/}
      {/*        /!*  selectTeethHandler={() => {}}*!/*/}
      {/*        /!*  selectDotForTeethBridgeHandler={() => {}}*!/*/}
      {/*        /!*  teethDiagramType={'Mixed'}*!/*/}
      {/*        /!*  teethDiagramTypeHandler={() => {}}*!/*/}
      {/*        /!*  isDisabled={false}*!/*/}
      {/*        /!*  upperFullJawSelection={() => {}}*!/*/}
      {/*        /!*  lowerFullJawSelection={() => {}}*!/*/}
      {/*        /!*  mixedFullJAwSelection={() => {}}*!/*/}
      {/*        /!*  selectionJawTypeAfterMixedSelect={''}*!/*/}
      {/*        /!*  mesurements={{ width: 200, height: 270, viewBox: '20 0, 485 859' }}*!/*/}
      {/*        /!*  printRx={true}*!/*/}
      {/*/>*/}
      {/*        <Box sx={{ height: '256px' }} />*/}
      {/*        /!* {userOrder?.products[0]?.teeth?.teethDentalNotation === DENTAL_NOTATION.FDA_SYSTEM ? (*/}
      {/*          <DentTeethSvg*/}
      {/*            teeth_svg_image_array={child_teeth_svg_image_array}*/}
      {/*            setTeeth_svg_image_array={setChild_Teeth_svg_image_array}*/}
      {/*            userOrderSelectedTeethArray={userOrderSelectedTeethArray}*/}
      {/*            selectTeethHandler={() => {}}*/}
      {/*            selectDotForTeethBridgeHandler={() => {}}*/}
      {/*            teethDiagramType={"Mixed"}*/}
      {/*            teethDiagramTypeHandler={() => {}}*/}
      {/*            isDisabled={false}*/}
      {/*            upperFullJawSelection={() => {}}*/}
      {/*            lowerFullJawSelection={() => {}}*/}
      {/*            mixedFullJAwSelection={() => {}}*/}
      {/*            selectionJawTypeAfterMixedSelect={""}*/}
      {/*            mesurements={{ width: 200, height: 270, viewBox: "20 0, 485 859" }}*/}
      {/*            printRx={true}*/}
      {/*          />*/}
      {/*        )  : userOrder?.products[0]?.teeth?.teethDentalNotation === DENTAL_NOTATION.PALMER_SYSTEM ?  (*/}
      {/*          <AdaDentTeethSvg*/}
      {/*            teeth_svg_image_array={child_teeth_svg_image_array}*/}
      {/*            setTeeth_svg_image_array={setChild_Teeth_svg_image_array}*/}
      {/*            userOrderSelectedTeethArray={userOrderSelectedTeethArray}*/}
      {/*            selectTeethHandler={() => {}}*/}
      {/*            selectDotForTeethBridgeHandler={() => {}}*/}
      {/*            teethDiagramType={"Mixed"}*/}
      {/*            teethDiagramTypeHandler={() => {}}*/}
      {/*            isDisabled={false}*/}
      {/*            upperFullJawSelection={() => {}}*/}
      {/*            lowerFullJawSelection={() => {}}*/}
      {/*            mixedFullJAwSelection={() => {}}*/}
      {/*            selectionJawTypeAfterMixedSelect={""}*/}
      {/*            mesurements={{ width: 200, height: 270, viewBox: "20 0, 485 859" }}*/}
      {/*            printRx={true}*/}
      {/*          />*/}
      {/*        ) : <PalmerDentTeethSvg*/}
      {/*        teeth_svg_image_array={child_teeth_svg_image_array}*/}
      {/*        setTeeth_svg_image_array={setChild_Teeth_svg_image_array}*/}
      {/*        userOrderSelectedTeethArray={userOrderSelectedTeethArray}*/}
      {/*        selectTeethHandler={() => {}}*/}
      {/*        selectDotForTeethBridgeHandler={() => {}}*/}
      {/*        teethDiagramType={"Mixed"}*/}
      {/*        teethDiagramTypeHandler={() => {}}*/}
      {/*        isDisabled={false}*/}
      {/*        upperFullJawSelection={() => {}}*/}
      {/*        lowerFullJawSelection={() => {}}*/}
      {/*        mixedFullJAwSelection={() => {}}*/}
      {/*        selectionJawTypeAfterMixedSelect={""}*/}
      {/*        mesurements={{ width: 200, height: 270, viewBox: "20 0, 485 859" }}*/}
      {/*        printRx={true}*/}
      {/*      /> } *!/*/}
      {/*      </Box>*/}

      {/*      <div>*/}
      {/*        <Typography fontWeight={'700'} fontSize={'10px'} lineHeight={'14px'}>*/}
      {/*          {'Permanent'}*/}
      {/*        </Typography>*/}
      {/*        <Box marginTop={'5px'}>*/}
      {/*          <div style={{ display: 'flex' }}>*/}
      {/*            {selectedUpperConstantTeeth.map((item: any, index: number) => (*/}
      {/*              <div*/}
      {/*                key={index}*/}
      {/*                style={*/}
      {/*                  index === 7*/}
      {/*                    ? {*/}
      {/*                        borderRight: '1px solid #d9d9d9',*/}
      {/*                        paddingRight: '12px',*/}
      {/*                      }*/}
      {/*                    : index === 8*/}
      {/*                      ? { paddingLeft: '12px' }*/}
      {/*                      : {}*/}
      {/*                }*/}
      {/*              >*/}
      {/*                <Typography fontWeight={'500'} fontSize={'6px'} lineHeight={'6px'} textAlign={'center'}>*/}
      {/*                  {item?.number}*/}
      {/*                  /!* {userOrder?.products[0]?.teeth?.teethDentalNotation === DENTAL_NOTATION.PALMER_SYSTEM ? item?.readableNumber : item?.number} *!/*/}
      {/*                </Typography>*/}
      {/*                {CHECK_ARRAY_IS_EMPTY_OR_NOT(userOrderSelectedTeethArray) ? (*/}
      {/*                  userOrderSelectedTeethArray.find(*/}
      {/*                    (subItme) => subItme.readableNumber === item?.readableNumber,*/}
      {/*                  ) ? (*/}
      {/*                    <img src={checkIcon} alt="" />*/}
      {/*                  ) : (*/}
      {/*                    <img src={unCheck} alt="" />*/}
      {/*                  )*/}
      {/*                ) : null}*/}
      {/*              </div>*/}
      {/*            ))}*/}
      {/*          </div>*/}
      {/*          <Divider />*/}
      {/*          <div style={{ display: 'flex' }}>*/}
      {/*            {selectedLowerConstantTeeth.map((item: any, index: number) => (*/}
      {/*              <div*/}
      {/*                key={index}*/}
      {/*                style={*/}
      {/*                  index === 7*/}
      {/*                    ? {*/}
      {/*                        borderRight: '1px solid #d9d9d9',*/}
      {/*                        paddingRight: '12px',*/}
      {/*                      }*/}
      {/*                    : index === 8*/}
      {/*                      ? { paddingLeft: '12px' }*/}
      {/*                      : {}*/}
      {/*                }*/}
      {/*              >*/}
      {/*                {CHECK_ARRAY_IS_EMPTY_OR_NOT(userOrderSelectedTeethArray) ? (*/}
      {/*                  userOrderSelectedTeethArray.find(*/}
      {/*                    (subItme) => subItme.readableNumber === item?.readableNumber,*/}
      {/*                  ) ? (*/}
      {/*                    <img src={checkIcon} alt="" />*/}
      {/*                  ) : (*/}
      {/*                    <img src={unCheck} alt="" />*/}
      {/*                  )*/}
      {/*                ) : null}*/}
      {/*                <Typography fontWeight={'500'} fontSize={'6px'} lineHeight={'6px'} textAlign={'center'}>*/}
      {/*                  {item?.number}*/}
      {/*                  /!* {userOrder?.products[0]?.teeth?.teethDentalNotation === DENTAL_NOTATION.PALMER_SYSTEM ? item?.readableNumber : item?.number} *!/*/}
      {/*                </Typography>*/}
      {/*              </div>*/}
      {/*            ))}*/}
      {/*          </div>*/}
      {/*        </Box>*/}
      {/*        <Typography fontWeight={'700'} fontSize={'10px'} lineHeight={'14px'} marginTop={'2rem'}>*/}
      {/*          {'Deciduous'}*/}
      {/*        </Typography>*/}
      {/*        <Box marginTop={'5px'}>*/}
      {/*          <div style={{ display: 'flex', paddingLeft: '25px' }}>*/}
      {/*            {selectedChildUpperConstantTeeth.map((item: any, index: number) => (*/}
      {/*              <div*/}
      {/*                key={index}*/}
      {/*                style={*/}
      {/*                  index === 4*/}
      {/*                    ? {*/}
      {/*                        borderRight: '1px solid #d9d9d9',*/}
      {/*                        paddingRight: '12px',*/}
      {/*                      }*/}
      {/*                    : index === 5*/}
      {/*                      ? { paddingLeft: '12px' }*/}
      {/*                      : {}*/}
      {/*                }*/}
      {/*              >*/}
      {/*                <Typography fontWeight={'500'} fontSize={'6px'} lineHeight={'6px'} textAlign={'center'}>*/}
      {/*                  {item?.number}*/}
      {/*                  /!* {userOrder?.products[0]?.teeth?.teethDentalNotation === DENTAL_NOTATION.PALMER_SYSTEM ? item?.readableNumber : item?.number} *!/*/}
      {/*                </Typography>*/}
      {/*                /!* {CHECK_ARRAY_IS_EMPTY_OR_NOT(userOrderSelectedTeethArray) ? (*/}
      {/*                  userOrderSelectedTeethArray.find(*/}
      {/*                    (subItme) => userOrder?.products[0]?.teeth?.teethDentalNotation === DENTAL_NOTATION.ADA_SYSTEM ? subItme?.readableNumber === checkTeethNumber(item) : CHECK_TEETH_NUMBER_BY_USER_SETTING(subItme, userOrder?.products[0]?.teeth?.teethDentalNotation) === checkTeethNumber(item)*/}
      {/*                  ) ? (*/}
      {/*                    <img src={checkIcon} alt="" />*/}
      {/*                  ) : (*/}
      {/*                    <img src={unCheck} alt="" />*/}
      {/*                  )*/}
      {/*                ) : null} *!/*/}
      {/*                {CHECK_ARRAY_IS_EMPTY_OR_NOT(userOrderSelectedTeethArray) ? (*/}
      {/*                  userOrderSelectedTeethArray.find(*/}
      {/*                    (subItme) => subItme?.readableNumber === checkTeethNumber(item),*/}
      {/*                  ) ? (*/}
      {/*                    <img src={checkIcon} alt="" />*/}
      {/*                  ) : (*/}
      {/*                    <img src={unCheck} alt="" />*/}
      {/*                  )*/}
      {/*                ) : null}*/}
      {/*              </div>*/}
      {/*            ))}*/}
      {/*          </div>*/}
      {/*          <Divider />*/}
      {/*          <div style={{ display: 'flex', paddingLeft: '25px' }}>*/}
      {/*            {selectedChildLowerConstantTeeth.map((item: any, index: number) => (*/}
      {/*              <div*/}
      {/*                key={index}*/}
      {/*                style={*/}
      {/*                  index === 4*/}
      {/*                    ? {*/}
      {/*                        borderRight: '1px solid #d9d9d9',*/}
      {/*                        paddingRight: '12px',*/}
      {/*                      }*/}
      {/*                    : index === 5*/}
      {/*                      ? { paddingLeft: '12px' }*/}
      {/*                      : {}*/}
      {/*                }*/}
      {/*              >*/}
      {/*                /!* {CHECK_ARRAY_IS_EMPTY_OR_NOT(userOrderSelectedTeethArray) ? (*/}
      {/*                  userOrderSelectedTeethArray.find(*/}
      {/*                    (subItme) => userOrder?.products[0]?.teeth?.teethDentalNotation === DENTAL_NOTATION.ADA_SYSTEM ? subItme?.readableNumber === checkTeethNumber(item) : CHECK_TEETH_NUMBER_BY_USER_SETTING(subItme, userOrder?.products[0]?.teeth?.teethDentalNotation) === checkTeethNumber(item)*/}
      {/*                  ) ? (*/}
      {/*                    <img src={checkIcon} alt="" />*/}
      {/*                  ) : (*/}
      {/*                    <img src={unCheck} alt="" />*/}
      {/*                  )*/}
      {/*                ) : null} *!/*/}
      {/*                {CHECK_ARRAY_IS_EMPTY_OR_NOT(userOrderSelectedTeethArray) ? (*/}
      {/*                  userOrderSelectedTeethArray.find(*/}
      {/*                    (subItme) => subItme?.readableNumber === checkTeethNumber(item),*/}
      {/*                  ) ? (*/}
      {/*                    <img src={checkIcon} alt="" />*/}
      {/*                  ) : (*/}
      {/*                    <img src={unCheck} alt="" />*/}
      {/*                  )*/}
      {/*                ) : null}*/}
      {/*                <Typography fontWeight={'500'} fontSize={'6px'} lineHeight={'6px'} textAlign={'center'}>*/}
      {/*                  {item?.number}*/}
      {/*                  /!* {userOrder?.products[0]?.teeth?.teethDentalNotation === DENTAL_NOTATION.PALMER_SYSTEM ? item?.readableNumber : item?.number} *!/*/}
      {/*                </Typography>*/}
      {/*              </div>*/}
      {/*            ))}*/}
      {/*          </div>*/}
      {/*        </Box>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </Box>*/}
      {/*</Box>*/}
      {/*<Box marginTop={'2rem'}>*/}
      {/*  {CHECK_ARRAY_IS_EMPTY_OR_NOT(userOrder?.products)*/}
      {/*    ? GROUP_PRODUCTS_BY_BRIDGE(userOrder?.products).map((item: any) => {*/}
      {/*        return (*/}
      {/*          <>*/}
      {/*            <Typography fontWeight={'700'} fontSize={'10px'} lineHeight={'14px'}>*/}
      {/*              {item.isBridge ? 'Bridge' : item.productName}*/}
      {/*            </Typography>*/}
      {/*            {item.products?.map((subItem: any) => {*/}
      {/*              return (*/}
      {/*                <Box display={'flex'}>*/}
      {/*                  <Typography fontWeight={'400'} fontSize={'8px'} lineHeight={'12px'}>*/}
      {/*                    {CHECK_TEETH_NUMBER_BY_USER_SETTING(*/}
      {/*                      subItem?.teeth,*/}
      {/*                      userOrder?.products[0]?.teeth?.teethDentalNotation,*/}
      {/*                    )}{' '}*/}
      {/*                    {subItem?.product} ({subItem?.method ? `${'Product option'}: ${subItem?.method} - ` : ''}{' '}*/}
      {/*                    {subItem?.material},{'Shade'} {subItem.teeth.shade})*/}
      {/*                  </Typography>*/}
      {/*                  <Typography fontWeight={'400'} fontSize={'8px'} lineHeight={'12px'} marginLeft={'auto'}>*/}
      {/*                    {subItem?.teeth?.currency} {subItem?.teeth?.price}*/}
      {/*                  </Typography>*/}
      {/*                </Box>*/}
      {/*              );*/}
      {/*            })}*/}
      {/*          </>*/}
      {/*        );*/}
      {/*      })*/}
      {/*    : null}*/}
    </Box>
  );
};

export const LowerElement = ({ rxPrintRef, userOrder }: Iprops) => {
  return (
    <Box
      ref={rxPrintRef}
      sx={{
        fontFamily: 'Inter',
        position: 'absolute',
        top: '100%',
        right: '100%',
        padding: '30px',
        width: '210mm',
        minHeight: 'fit-content',
        boxSizing: 'border-box',
      }}
    >
      <PaymentInfoSection userOrder={userOrder} />
      <Divider style={{ margin: '28px 0 2px' }} />

      <ShippingAddressInfoSection userOrder={userOrder} />
      <Divider style={{ margin: '20px 0 10px' }} />
    </Box>
  );
};

interface Props {
  userOrder: OrderDetailResponse;
}
const PatientInfoSection = ({ userOrder }: Props) => {
  return (
    <>
      <Stack direction="row">
        {/* Logo */}
        <Box sx={{ mt: '16px', mr: '24px' }}>
          <img width={'100%'} src={BrandIcon} alt="Dentbird logo" />
        </Box>

        {/* Patient Info */}
        <Stack style={{ width: '100%' }}>
          {PATIENT_INFO(userOrder).map((info) => (
            <Stack key={info.key} direction="row" gap="20px">
              <Box width="120px">
                <Typography fontWeight={'600'} fontSize={'8px'} lineHeight={'12px'} sx={{ fontFamily: 'inherit' }}>
                  {info.key}
                </Typography>
              </Box>
              <Box width="100%">
                <Typography fontWeight={'400'} fontSize={'8px'} lineHeight={'12px'}>
                  {info.value}
                </Typography>
              </Box>
            </Stack>
          ))}
        </Stack>

        {/* Lab Info. */}
        <Stack direction="row" sx={{ width: '100%' }}>
          <Box sx={{ width: '110px' }}>
            <Typography fontWeight={'400'} fontSize={'8px'} lineHeight={'12px'} sx={{ fontFamily: 'inherit' }}>
              {'Laboratory Info.'}
            </Typography>
          </Box>
          {userOrder.lab ? (
            <Box sx={{ width: '100%' }}>
              <Typography fontWeight={'400'} fontSize={'8px'} lineHeight={'12px'} sx={{ fontFamily: 'inherit' }}>
                {userOrder.lab.organization.name}
              </Typography>
              <Typography fontWeight={'400'} fontSize={'8px'} lineHeight={'12px'} sx={{ fontFamily: 'inherit' }}>
                {formattedAddress(userOrder.lab.organization.address!)}
              </Typography>
            </Box>
          ) : (
            <Box width="100%">
              <Typography fontWeight={'400'} fontSize={'8px'} lineHeight={'12px'}>
                -
              </Typography>
            </Box>
          )}
        </Stack>
      </Stack>
      <Divider style={{ margin: '20px 0 2px' }} />

      <Stack sx={{ marginBottom: '12px' }}>
        {ORDER_INFO(userOrder).map(({ key, value }) => (
          <Stack key={key} direction="row" gap="20px">
            <Box width="156px">
              <Typography fontWeight={'700'} fontSize={'10px'} lineHeight={'14px'} sx={{ fontFamily: 'inherit' }}>
                {key}
              </Typography>
            </Box>
            <Box width="100%">
              <Typography fontWeight={'400'} fontSize={'8px'} lineHeight={'12px'}>
                {value}
              </Typography>
            </Box>
          </Stack>
        ))}
      </Stack>
    </>
  );
};

const OrderInfoSection = ({ userOrder }: Props) => {
  const imageFiles2D = userOrder.patientFilesResponse.filter((fileInfo) => {
    const extension = fileInfo.fileName.split('.').pop()?.toLowerCase() as string;
    return (
      extension === 'png' || extension === 'jpg' || extension === 'jpeg' || extension === 'bmp' || extension === 'webp'
    );
  });
  return (
    <>
      <Stack>
        <Box sx={{ marginBottom: '8px' }}>
          <Typography fontWeight={'700'} fontSize={'10px'} lineHeight={'14px'} sx={{ fontFamily: 'inherit' }}>
            Order item
          </Typography>
        </Box>

        <Stack gap="6px" sx={{ marginBottom: '12px' }}>
          {userOrder.orderItems.map((item) => {
            const hookOption = `${capitalizeFirstLetter(item.hook)} ${item.hookPrice ? `(+ ${formattedPrice(item.hookPrice)})` : ''}`;
            const finishOption = `${item.premiumFinishing ? 'Premium' : 'Basic'} ${item.premiumFinishingPrice ? `(+ ${formattedPrice(item.premiumFinishingPrice)})` : ''}`;

            return (
              <Stack
                key={item.toothNumber}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ gap: '66px' }}
              >
                <Box key={item.toothNumber}>
                  <Typography fontWeight={'400'} fontSize={'10px'} lineHeight={'14px'} sx={{ fontFamily: 'inherit' }}>
                    {FDIToPalmer[item.toothNumber]?.short ?? '#'}
                  </Typography>
                  <Typography fontWeight={'400'} fontSize={'10px'} lineHeight={'14px'} sx={{ fontFamily: 'inherit' }}>
                    {`Product type: ${item.prosthesis}, Product option: ${item.method} - ${item.material} - ${item.shape}, Shade: ${item.shadeType}, Hook: ${hookOption}, Finish option: ${finishOption}`}
                  </Typography>
                </Box>
                <Box sx={{ minWidth: 'fit-content' }}>
                  <Typography fontWeight={'400'} fontSize={'10px'} lineHeight={'14px'} sx={{ fontFamily: 'inherit' }}>
                    {formattedPrice(item.amount)}
                  </Typography>
                </Box>
              </Stack>
            );
          })}
        </Stack>
      </Stack>

      <Divider style={{ margin: '20px 0 2px' }} />

      {userOrder.patientPrintingFilesResponse.length > 0 && (
        <>
          <Stack>
            <Box sx={{ marginBottom: '8px' }}>
              <Typography fontWeight={'700'} fontSize={'10px'} lineHeight={'14px'} sx={{ fontFamily: 'inherit' }}>
                Additional options
              </Typography>
            </Box>

            <Box sx={{ marginBottom: '12px' }}>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Box>
                  <Typography fontWeight={'400'} fontSize={'10px'} lineHeight={'14px'} sx={{ fontFamily: 'inherit' }}>
                    {`3D model (Order quantity: ${userOrder.patientPrintingFilesResponse.length} ${userOrder.patientPrintingFilesResponse.length > 1 ? 'files' : 'file'})`}
                  </Typography>
                </Box>
                <Box>
                  <Typography fontWeight={'400'} fontSize={'10px'} lineHeight={'14px'} sx={{ fontFamily: 'inherit' }}>
                    {formattedPrice(userOrder.printingFilesAmount)}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Stack>
          <Divider style={{ margin: '12px 0 6px' }} />
        </>
      )}

      <Stack sx={{ marginBottom: '8px' }}>
        <Box sx={{ marginBottom: '4px' }}>
          <Typography fontWeight={'700'} fontSize={'10px'} lineHeight={'14px'} sx={{ fontFamily: 'inherit' }}>
            Note
          </Typography>
        </Box>

        <Stack sx={{ marginTop: '12px', padding: '1px 8px 20px', backgroundColor: '#f6f7f8' }} justifyContent="center">
          <Typography fontWeight={'400'} fontSize={'10px'} lineHeight={'14px'} sx={{ fontFamily: 'inherit' }}>
            {userOrder.note || '-'}
            {/*TEST: Make the future of digital medicine for everyone...! I'm just wondering if this section would be wandered or*/}
            {/*not, when it's tooooooo long enought to go longggg*/}
          </Typography>
        </Stack>
      </Stack>

      <Stack>
        <Box sx={{ marginBottom: '20px' }}>
          <Typography fontWeight={'700'} fontSize={'10px'} lineHeight={'14px'} sx={{ fontFamily: 'inherit' }}>
            Photos
          </Typography>
        </Box>

        {imageFiles2D.length ? (
          <Stack gap="20px" direction="row" sx={{ flexWrap: 'wrap', padding: '12px 8px', backgroundColor: '#f6f7f8' }}>
            {imageFiles2D.map((fileInfo) => (
              <img key={fileInfo.fileName} src={fileInfo.fileUrl} alt={fileInfo.fileName} height="90" />
            ))}
          </Stack>
        ) : (
          <Stack
            sx={{ marginTop: '12px', padding: '1px 8px 20px', backgroundColor: '#f6f7f8' }}
            justifyContent="center"
          >
            <Typography fontWeight={'400'} fontSize={'10px'} lineHeight={'14px'} sx={{ fontFamily: 'inherit' }}>
              -
            </Typography>
          </Stack>
        )}
      </Stack>
    </>
  );
};

const PaymentInfoSection = ({ userOrder }: Props) => {
  const paymentList = PAYMENT_INFO(userOrder);

  return (
    <Stack>
      <Box sx={{ marginBottom: '8px' }}>
        <Typography fontWeight={'700'} fontSize={'10px'} lineHeight={'14px'} sx={{ fontFamily: 'inherit' }}>
          Payment
        </Typography>
      </Box>

      <Stack gap="4px" sx={{ width: '220px' }}>
        {paymentList.map((item, index) => {
          const boldIndices = ['0', (paymentList.length - 1).toString()];
          const colorChangeIndex = paymentList.length - 1;

          if (item.key === 'Divider') return <Divider key={item.key} style={{ margin: '12px 0 -2px' }} />;
          return (
            <Stack direction="row" justifyContent="space-between" key={item.key}>
              <Box>
                <Typography
                  fontWeight={boldIndices.includes(index.toString()) ? '600' : '400'}
                  fontSize={'10px'}
                  lineHeight={'14px'}
                  sx={{ fontFamily: 'inherit' }}
                >
                  {item.key}
                </Typography>
              </Box>
              <Box>
                <Typography
                  fontWeight={boldIndices.includes(index.toString()) ? '600' : '400'}
                  fontSize={'10px'}
                  lineHeight={'14px'}
                  sx={{ fontFamily: 'inherit' }}
                  color={index === colorChangeIndex ? '#0083CA' : 'text.primary'}
                >
                  {item.value}
                </Typography>
              </Box>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};

const ShippingAddressInfoSection = ({ userOrder }: Props) => {
  return (
    <>
      <Stack>
        <Box sx={{ marginBottom: '12px' }}>
          <Typography fontWeight={'700'} fontSize={'10px'} lineHeight={'14px'} sx={{ fontFamily: 'inherit' }}>
            Shipping address
          </Typography>
        </Box>

        <Stack direction="column">
          <Typography fontWeight={'400'} fontSize={'10px'} lineHeight={'14px'} sx={{ fontFamily: 'inherit' }}>
            {userOrder.receiver?.number.number}
          </Typography>
          <Typography fontWeight={'400'} fontSize={'10px'} lineHeight={'14px'} sx={{ fontFamily: 'inherit' }}>
            {formattedAddress(userOrder.shippingAddress!)}
          </Typography>
          <Typography
            fontWeight={'400'}
            fontSize={'10px'}
            lineHeight={'14px'}
            sx={{ fontFamily: 'inherit', marginTop: '4px' }}
          >
            {`Delivery instruction: ${userOrder.deliveryInstruction || '-'}`}
          </Typography>
        </Stack>
      </Stack>

      {/*<Divider style={{ margin: '24px 0 6px' }} />*/}

      {/*<Stack>*/}
      {/*  <Box sx={{ marginBottom: '10px' }}>*/}
      {/*    <Typography fontWeight={'700'} fontSize={'10px'} lineHeight={'14px'} sx={{ fontFamily: 'inherit' }}>*/}
      {/*      Payment method*/}
      {/*    </Typography>*/}
      {/*  </Box>*/}

      {/*  <Box>*/}
      {/*    <Typography fontWeight={'400'} fontSize={'10px'} lineHeight={'14px'} sx={{ fontFamily: 'inherit' }}>*/}
      {/*      Pay later*/}
      {/*    </Typography>*/}
      {/*  </Box>*/}
      {/*</Stack>*/}
    </>
  );
};

export type SelectedToothListType = Array<{
  prosthesis: string;
  material: string;
  toothNumber: number;
  groupId?: string | null;
}>;

type PalmerPropType = {
  selectedToothList: SelectedToothListType; // toothNumber[]
  onToothClick?: (clickedToothNumber: number) => void;
  onBridgeClick?: (clickedBridgeNumberPair: string) => void;
  selectedBridgeNumberPairList: Array<string>;
  style?: Record<string, string>;
};

const RxPalmer = ({
  selectedToothList,
  onToothClick,
  onBridgeClick,
  selectedBridgeNumberPairList,
  style,
}: Readonly<PalmerPropType>) => {
  const { pathname } = useLocation();
  const isViewOnly = pathname.includes('remake');

  const getToothStyle = (id: number) => {
    const selected = selectedToothList.find((selected) => selected.toothNumber === id);
    if (!selected) return 'DEFAULT';

    if (selected && selected.prosthesis === '') return 'CLICKED';
    else if (selected.prosthesis === 'CROWN') return 'CROWN_SELECTED';
    else if (selected.prosthesis === 'IN_ONLAY' || selected.prosthesis === 'ONLAY') return 'INLAY_ONLAY_SELECTED';
    else if (selected && selected.prosthesis !== '') return 'CROWN_SELECTED';
    else return 'DEFAULT';
  };

  const getBridgeStyle = (id: string): BRIDGE_STYLE_KEY => {
    if (selectedBridgeNumberPairList.includes(id)) return 'SELECTED';
    // if (availableBridgeList.includes(id)) return 'AVAILABLE';
    return 'DEFAULT';
  };

  return (
    <Box component={'svg'} sx={{ width: '100%', height: '100%' }}>
      {/* Permanent 경우 */}
      {RX_BRIDGE_DATA_SET.map(({ id, cx, cy, r, transform }) => {
        return (
          <Box
            key={id}
            component="circle"
            onClick={() => {
              if (isViewOnly) return;
              onBridgeClick && onBridgeClick(id);
            }}
            sx={{ ...BRIDGE_STYLE[getBridgeStyle(id as BRIDGE_STYLE_KEY)] }}
            cx={cx}
            cy={cy}
            r={r}
            transform={transform && transform}
          />
        );
      })}
      {RX_PALMER_DATA_SET.map((el) => {
        return (
          <Box
            key={el.id}
            component={'g'}
            onClick={() => {
              if (isViewOnly) return;
              onToothClick && onToothClick(el.id);
            }}
            sx={{
              ...TOOTH_STYLE[getToothStyle(el.id)],
              ...(isViewOnly ? TOOTH_STYLE.VIEW_ONLY : {}),
              width: '132px',
              height: '224px',
            }}
          >
            <path className="tooth" d={el.tooth.d} stroke="#CDD1D5" fill="#FFF" />
            <path className="text" d={el.text.d} fill="#6A6E74" />
          </Box>
        );
      })}
    </Box>
  );
};

const RxSquare = ({
  selectedToothList,
  onToothClick,
  onBridgeClick,
  selectedBridgeNumberPairList,
  style,
}: Readonly<PalmerPropType>) => {
  const { pathname } = useLocation();
  const isViewOnly = pathname.includes('remake');

  const getToothStyle = (id: number) => {
    const selected = selectedToothList.find((selected) => selected.toothNumber === id);
    if (!selected) return 'DEFAULT';

    if (selected && selected.prosthesis === '') return 'CLICKED';
    else if (selected.prosthesis === 'CROWN') return 'CROWN_SELECTED';
    else if (selected.prosthesis === 'IN_ONLAY' || selected.prosthesis === 'ONLAY') return 'INLAY_ONLAY_SELECTED';
    else if (selected && selected.prosthesis !== '') return 'CROWN_SELECTED';
    else return 'DEFAULT';
  };

  const getBridgeStyle = (id: string): BRIDGE_STYLE_KEY => {
    if (selectedBridgeNumberPairList.includes(id)) return 'SELECTED';
    // if (availableBridgeList.includes(id)) return 'AVAILABLE';
    return 'DEFAULT';
    // return 'SELECTED';
  };

  return (
    <Stack gap="20px">
      <Typography fontWeight={'700'} fontSize={'10px'} lineHeight={'14px'} sx={{ fontFamily: 'inherit' }}>
        Permanent
      </Typography>
      <Box component={'svg'} sx={{ width: '100%', height: '100%' }}>
        <rect x="12" y="33" width="245" height="1" rx="0.5" fill="#D9D9D9" />
        <rect x="132" y="53" width="39" height="1" transform="rotate(-90 132 53)" fill="#D9D9D9" />
        <path
          d="M0.639205 35V29.1818H2.71307C3.16383 29.1818 3.53788 29.2595 3.83523 29.4148C4.13447 29.5701 4.35795 29.785 4.50568 30.0597C4.65341 30.3324 4.72727 30.6477 4.72727 31.0057C4.72727 31.3617 4.65246 31.6752 4.50284 31.946C4.35511 32.215 4.13163 32.4242 3.83239 32.5739C3.53504 32.7235 3.16098 32.7983 2.71023 32.7983H1.1392V32.0426H2.63068C2.91477 32.0426 3.14583 32.0019 3.32386 31.9205C3.50379 31.839 3.63542 31.7206 3.71875 31.5653C3.80208 31.41 3.84375 31.2235 3.84375 31.0057C3.84375 30.786 3.80114 30.5956 3.71591 30.4347C3.63258 30.2737 3.50095 30.1506 3.32102 30.0653C3.14299 29.9782 2.90909 29.9347 2.61932 29.9347H1.51705V35H0.639205ZM3.51136 32.375L4.94886 35H3.94886L2.53977 32.375H3.51136Z"
          fill="#6A6E74"
        />
        <path d="M261.639 35V29.1818H262.517V34.2443H265.153V35H261.639Z" fill="#6A6E74" />
        {/*Permanent 경우*/}
        {RX_SQUARE_BRIDGE_SET.map(({ id, cx, cy, r, transform }) => {
          return (
            <Box
              key={id}
              component="circle"
              // onClick={() => {
              //   if (isViewOnly) return;
              //   onBridgeClick && onBridgeClick(id);
              // }}
              sx={{ ...BRIDGE_STYLE[getBridgeStyle(id as BRIDGE_STYLE_KEY)] }}
              cx={cx}
              cy={cy}
              r={r}
              transform={transform}
              // stroke="#CDD1D5"
            />
          );
        })}
        {RX_SQUARE_DATA_SET.map((el) => {
          return (
            <Box
              key={el.id}
              component={'g'}
              onClick={() => {
                if (isViewOnly) return;
                onToothClick && onToothClick(el.id);
              }}
              sx={{
                ...TOOTH_STYLE[getToothStyle(el.id)],
                ...(isViewOnly ? TOOTH_STYLE.VIEW_ONLY : {}),
                width: '132px',
                height: '224px',
                fill: '#fff',
              }}
            >
              <path className="tooth" d={el.tooth.d} stroke="#CDD1D5" fill="#FFF" />
              <rect
                className="square"
                x={el.tooth.x}
                y={el.tooth.y}
                width={el.tooth.width}
                height={el.tooth.height}
                rx={el.tooth.rx}
                stroke="#6A6E74"
              />
              <path className="text" d={el.text.d} fill="#6A6E74" />
            </Box>
          );
        })}
      </Box>
    </Stack>
  );
};
