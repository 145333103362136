import React, { useState } from 'react';
import { Box, Button, Divider, FormControl, MenuItem, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { ColorPair, optionGroup } from '../../../client';
import CustomDialog from '../../../components/dialog/CustomDialog';
import Iconify from '../../../components/iconify/Iconify';
import { useDialogue } from '../../../constants/helperHooks/useDialogue';
import {
  useProductOptionDelete,
  useProductOptionMutation,
  useProductOptions,
  useProsthesisColor,
} from '../../../recoil/products/useProduct';
import { capitalizeFirstLetter } from '../../../utils/formatString';
import { useAxiosErrorWithErrorCode } from '../../../utils/responseError';

const OPTION_GROUP = [
  { name: 'PROSTHESIS' },
  { name: 'METHOD' },
  { name: 'MATERIAL' },
  { name: 'SHAPE' },
  // { name: 'PRICE_UNIT' },
  // { name: 'COLOR_CODE' },
];

const PALETTE_OPTION = [
  { name: 'Dark Blue', stroke: '#0D3B66', fill: '#E5F2F9' },
  { name: 'Brick Red', stroke: '#C0392B', fill: '#F9EBEA' },
  { name: 'Forest Green', stroke: '#2E7D32', fill: '#E8F5E9' },
  { name: 'Tangerine', stroke: '#EF6C00', fill: '#FFF3E0' },
  { name: 'Purple', stroke: '#0277BD', fill: '#E1F5FE' },
  { name: 'Maroon', stroke: '#662222', fill: '#F9E5E5' },
  { name: 'Sky Blue', stroke: '#8E24AA', fill: '#F3E5F5' },
  { name: 'Coral', stroke: '#E91E63', fill: '#FFF0F0' },
  { name: 'Deep Purple', stroke: '#3949AB', fill: '#E8EAF6' },
  { name: 'Golden Yellow', stroke: '#F9A825', fill: '#FFFDE7' },
  { name: 'Dark Lavender', stroke: '#5E35B1', fill: '#EDE7F6' },
  { name: 'Light Green', stroke: '#7CB342', fill: '#F1F8E9' },
  { name: 'Dark Gray', stroke: '#607D8B', fill: '#ECEFF1' },
  { name: 'Olive Green', stroke: '#9E9D24', fill: '#F9FBE7' },
  { name: 'Slate Gray', stroke: '#424242', fill: '#F3F3F3' },
  { name: 'Bright Pink', stroke: '#D84315', fill: '#FBE9E7' },
];

// Dark Blue
const defaultColor = { stroke: '', fill: '' };

const ERROR_MESSAGE = {
  'CONNECT:PRODUCT_OPTION_IS_USED': {
    title: 'Unable to delete',
    message: 'This item is used.',
  },
  'CONNECT:PRODUCT_OPTION_ALREADY_EXISTS': {
    title: 'This product already exists',
    message: 'Please verify this product before proceeding.',
  },
  // TODO: 서버에서 정의 되어 있지만, 서버까지 안가도 됨. 클라단에서만 사용. 서버와 맞출 필요 있음.
  // 'CONNECT:PRODUCT_OPTION_COLOR_CODE_IS_EMPTY': {
  //   title: 'Unable to add',
  //   message: 'Color is required.',
  // },
};

interface ProductOptionEditDialogProps {
  isOpen: boolean;
  handleClose: () => void;
}

export default function ProductOptionEditDialog({ isOpen, handleClose }: ProductOptionEditDialogProps) {
  const { mutateAsync: addProductOption } = useProductOptionMutation();
  const { mutateAsync: deleteProductOption } = useProductOptionDelete();

  const [selectedProductOption, setSelectedProductOption] = useState<optionGroup>('PROSTHESIS');

  const [isAddMode, setIsAddMode] = useState(false);
  const [typedOption, setTypedOption] = useState('');
  const [selectedColor, setSelectedColor] = useState<ColorPair>(defaultColor);

  // TODO: useSuspenseQuery 사용할 것.
  const { data, refetch: productOptionsRefetch } = useProductOptions(selectedProductOption);
  const { isAxiosErrorWithErrorCode, errorCode, resetError } = useAxiosErrorWithErrorCode();

  // Colour
  const { data: prosthesisColor, refetch: prosthesisColorRefetch } = useProsthesisColor();

  // Dialog for unable to delete option.
  const {
    open: isUnableToDeleteOpen,
    handleOpenDialogue: unableToDeleteDialogOpen,
    handleCloseDialogue: unableToDeleteDialogClose,
  } = useDialogue();

  const handleOptionClick = (e: React.SyntheticEvent<Element, Event>, newValue: string) => {
    setSelectedProductOption(newValue as optionGroup);
    setIsAddMode(false);
    setTypedOption('');
    setSelectedColor(defaultColor);
  };
  const handleAddModeClick = () => {
    setIsAddMode((prev) => !prev);
  };

  const handleTypedOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target as HTMLInputElement;
    setTypedOption(value);
  };

  const handleSelectedColorChange = (e: SelectChangeEvent) => {
    const { value } = e.target;
    const { stroke, fill } = PALETTE_OPTION.find((color) => color.name === value) as ColorPair;
    setSelectedColor({ stroke, fill });
  };

  const handleAddClick = async () => {
    if (!typedOption) {
      return unableToDeleteDialogOpen();
    }
    try {
      const res = await addProductOption({
        optionGroup: selectedProductOption,
        value: typedOption,
        colorCode: selectedColor,
      });

      if (res.success) {
        setTypedOption('');
        setSelectedColor(defaultColor);
        setIsAddMode(false);
      }
      // like invalidate
      await productOptionsRefetch();
      await prosthesisColorRefetch();
    } catch (err) {
      if (isAxiosErrorWithErrorCode(err, 'CONNECT:PRODUCT_OPTION_ALREADY_EXISTS')) {
        unableToDeleteDialogOpen();
      }
    }
  };

  const handleDeleteClick = async (id: string) => {
    try {
      await deleteProductOption(id);
      // like invalidate
      await productOptionsRefetch();
      await prosthesisColorRefetch();
    } catch (err) {
      if (isAxiosErrorWithErrorCode(err, 'CONNECT:PRODUCT_OPTION_IS_USED')) {
        unableToDeleteDialogOpen();
      }
    }
  };

  const selectedColorName =
    PALETTE_OPTION.find((color) => color.stroke === selectedColor?.stroke && color.fill === selectedColor?.fill)
      ?.name ?? '';

  return (
    <>
      <CustomDialog
        open={isOpen}
        title={'Edit category'}
        iconClose={true}
        handleClose={() => {
          handleClose();
          setIsAddMode(false);
          setTypedOption('');
          setSelectedColor(defaultColor);
          setSelectedProductOption('PROSTHESIS');
        }}
        isActionSection={false}
        customStyle={{
          width: '566px !important',
          height: '563px !important',
        }}
      >
        <Stack gap="20px">
          <Tabs onChange={handleOptionClick} value={selectedProductOption} sx={{ margin: '0 !important' }}>
            {OPTION_GROUP.map(({ name }) => (
              <Tab
                key={name}
                label={capitalizeFirstLetter(name)}
                value={name}
                sx={{
                  marginRight: '20px !important',
                }}
              />
            ))}
          </Tabs>

          <Typography variant="body2" color="text.secondary">
            *Changes applied and saved automatically
          </Typography>

          <Stack direction="row" sx={{ width: '100%' }} justifyContent="flex-start" gap="8px">
            {!isAddMode && (
              <Button
                variant="outlined"
                size="medium"
                disabled={isAddMode}
                onClick={handleAddModeClick}
                startIcon={<Iconify icon="mingcute:add-line" sx={{ width: 15, height: 15 }} />}
                sx={{ minWidth: '110px', height: '40px' }}
              >
                Add new
              </Button>
            )}
            {isAddMode && (
              <Stack direction="row" gap="11px" justifyContent="space-between">
                {/* Add Value*/}
                <TextField
                  value={typedOption}
                  onChange={handleTypedOption}
                  size="small"
                  placeholder={`Enter ${selectedProductOption.toLowerCase()}`}
                  sx={{ width: '182px' }}
                />
                {/* Select */}
                {selectedProductOption === 'PROSTHESIS' && (
                  <Box>
                    <FormControl
                      fullWidth
                      size="small"
                      sx={{
                        height: '40px',
                      }}
                    >
                      <InputLabel
                        id="select-color"
                        sx={{
                          '&.MuiInputLabel-shrink': {
                            display: 'none',
                          },
                        }}
                      >
                        Color
                      </InputLabel>
                      <Select
                        labelId="select-color"
                        value={selectedColorName}
                        label="Color"
                        onChange={handleSelectedColorChange}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              padding: '4px',
                              maxHeight: '232px',
                              overflowY: 'auto',
                              '& .MuiList-root': {
                                padding: '0px !important',
                              },
                              '& .MuiMenuItem-root': {
                                marginBottom: '4px',
                                padding: '6px 8px',
                                borderRadius: '6px',
                                '&:last-child': {
                                  marginBottom: '0px',
                                },
                              },
                            },
                          },
                        }}
                        sx={{
                          width: '184px',
                          '& fieldset legend': {
                            width: '0px',
                          },
                        }}
                      >
                        {PALETTE_OPTION.map(({ name, fill, stroke }) => {
                          return (
                            <MenuItem key={name} value={name}>
                              <Stack direction="row" gap="8px" justifyContent="flex-start" alignItems="center">
                                <ColorBox fill={fill} stroke={stroke} />
                                <Typography variant={selectedColor.stroke === stroke ? 'subtitle2' : 'body2'}>
                                  {name}
                                </Typography>
                              </Stack>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                )}
                <Button
                  onClick={handleAddClick}
                  sx={{ minWidth: '64px' }}
                  disabled={!typedOption || (selectedColor === defaultColor && selectedProductOption === 'PROSTHESIS')}
                  size="medium"
                  variant="contained"
                >
                  Add
                </Button>
              </Stack>
            )}
          </Stack>

          <Divider />

          <Stack sx={{ height: '284px', overflowY: 'auto' }}>
            {data?.map((option) => {
              const isProsthesisTab = selectedProductOption === 'PROSTHESIS';
              const color =
                isProsthesisTab && prosthesisColor?.data?.find((item) => item.productOptionId === option.id);

              return (
                <Stack
                  key={option.value}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    width: '100%',
                    padding: '16px 12px',
                    '&:hover': { backgroundColor: 'action.hover', borderRadius: '8px' },
                  }}
                >
                  <Stack sx={{ width: '85px', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                        textAlign: 'center',
                      }}
                    >
                      {option.value}
                    </Typography>
                  </Stack>
                  {isProsthesisTab && color ? (
                    <Stack
                      gap="8px"
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      sx={{
                        width: '142px',
                      }}
                    >
                      <ColorBox fill={color.colorCode.fill} stroke={color.colorCode.stroke} />
                      <Typography variant="caption" color="text.secondary">
                        {PALETTE_OPTION.find((option) => option.stroke === color.colorCode.stroke)?.name}
                      </Typography>
                    </Stack>
                  ) : null}
                  <Button onClick={() => handleDeleteClick(option.id)} variant="contained" color="error" size="small">
                    Delete
                  </Button>
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      </CustomDialog>

      <ErrorDialogWithMessage
        errorMessage={ERROR_MESSAGE[errorCode as keyof typeof ERROR_MESSAGE]}
        open={isUnableToDeleteOpen}
        onClose={() => {
          unableToDeleteDialogClose();
          resetError();
        }}
      />
    </>
  );
}

const ColorBox = (color: ColorPair) => {
  return (
    <Box border={color.stroke}>
      <Box sx={{ width: '22px', height: '22px', backgroundColor: color.stroke, borderRadius: '8px' }} />
    </Box>
  );
};

interface DeleteUnableDialogProps {
  open: boolean;
  onClose: () => void;
  errorMessage:
    | {
        title: string;
        message: string;
      }
    | undefined;
}
function ErrorDialogWithMessage({ open, onClose, errorMessage }: DeleteUnableDialogProps) {
  return (
    <CustomDialog
      open={open}
      title={errorMessage?.title}
      btnAction={onClose}
      isCancelSection={false}
      btnActionText={'Confirm'}
      customStyle={{ width: '539px' }}
    >
      <Stack sx={{ py: '12px' }}>
        <Typography variant="body1" color="text.secondary">
          {errorMessage?.message}
        </Typography>
      </Stack>
    </CustomDialog>
  );
}
