import React, { useState } from 'react';
import { Button, Card, Stack, Typography } from '@mui/material';

import Iconify from '../../../components/iconify/Iconify';
import { SEARCH_TARGET_TYPE } from '../../../components/selectInput/SearchTargetSelect';
import CommonFilterBar, { CaseToolbarDataTypes } from '../../../components/table/CommonFilterBar';
import useTable from '../../../components/table/useTable';
import { usePaymentHistoryListQuery } from '../../../recoil/sales/useSales';
import PaymentHistoryListTable from './PaymentHistoryListTable';

enum FILTER_TYPE {
  USER_EMAIL = 'USER_EMAIL',
  ORDER_NUMBER = 'ORDER_NUMBER',
  TRANSACTION_ID = 'STRIPE_PAYMENT_INTENT_ID',
}

const paymentHistorySearchTargetList: SEARCH_TARGET_TYPE[] = [
  { id: FILTER_TYPE.USER_EMAIL, label: 'User email' },
  { id: FILTER_TYPE.ORDER_NUMBER, label: 'Order number' },
  { id: FILTER_TYPE.TRANSACTION_ID, label: 'Transaction ID' },
];

const PaymentHistoryPage = () => {
  // const { getInvoicesForAdminListing, getInvoiceById, allInvoiceListing, isLoading } = useInvoiceActions();

  // const getInvoiceByIdHandler = (id: string) => {
  //   if (id) {
  //     getInvoiceById(id).then((res) => {
  //       setOpenDetailDlg(true);
  //     });
  //   }
  // };

  const { dense, page, rowsPerPage, onChangeDense, onChangePage, onChangeRowsPerPage, setPage } = useTable({
    listName: 'paymentHistoryList',
    defaultRowsPerPage: 10,
    defaultCurrentPage: 0,
  });
  const [extraFilter, setExtraFilter] = useState({});

  const { data, isPending } = usePaymentHistoryListQuery({
    option: { page: page + 1, size: rowsPerPage, ...extraFilter },
  });
  const paymentHistoryListData = data?.data?.content;
  const totalElement = data?.data?.totalElements ?? 0;

  const resetPage = () => {
    setPage(0);
  };

  const handleDataChange = (data: CaseToolbarDataTypes) => {
    const { startDate, endDate, searchText, searchTarget } = data;
    setExtraFilter({
      startDate: startDate?.toISOString(),
      endDate: endDate?.add(1, 'day').toISOString(),
      ...(searchText !== '' ? { filterType: !searchTarget && searchText ? FILTER_TYPE.USER_EMAIL : searchTarget } : {}),
      ...(searchText !== '' && { searchKeyword: searchText }),
    });
    resetPage();
  };

  return (
    <Stack gap="40px" sx={{ px: 7, py: 5 }}>
      <Typography variant="h3">Payment history</Typography>
      <Card>
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ padding: '24px' }}>
          <CommonFilterBar
            filterList={['date', 'searchTarget', 'searchText']}
            resetPage={resetPage}
            onDataChange={handleDataChange}
            searchTargetList={paymentHistorySearchTargetList}
            searchTargetName={'Search target'}
          />
          <Button
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<Iconify icon="ic:outline-file-download" />}
            disabled={!isPending && !paymentHistoryListData?.length}
            sx={{ minWidth: '98px' }}
          >
            Export
          </Button>
        </Stack>
        <PaymentHistoryListTable
          dense={dense}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangeDense={onChangeDense}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          paymentHistoryList={paymentHistoryListData}
          totalElement={totalElement}
          isPending={isPending}
        />
      </Card>
      {/*<SalesHistoryTableToolbar*/}
      {/*  allLabList={modifiedLabListing ? modifiedLabListing : []}*/}
      {/*  selectedInvoicesForInprogress={selectedInvoicesForInprogress}*/}
      {/*  setSelectedInvoicesForInprogress={setSelectedInvoicesForInprogress}*/}
      {/*  invoiceListing={invoiceListing}*/}
    </Stack>
  );
};

export default PaymentHistoryPage;
